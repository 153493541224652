import { Stack, styled, Typography } from '@mui/material';
import { StackDirectionEnum } from 'enums/StackDirectionEnum';
import colors from 'styles/colors';

export const HeaderCellContainer = styled(Stack)(() => ({
  padding: '0.8rem 1.6rem',
}));

export const HeaderCellValue = styled(Typography)(() => ({
  color: colors.black,
  paddingBottom: '0.5rem',
}));
HeaderCellValue.defaultProps = {
  // @ts-ignore
  variant: 'headerStackedCellValue',
  whiteSpace: 'initial',
  align: 'center',
};

export const HeaderCellLabel = styled(Typography)(() => ({
  color: colors.midnightLight,
}));
HeaderCellLabel.defaultProps = {
  // @ts-ignore
  variant: 'headerStackedCellLabel',
  whiteSpace: 'nowrap',
  align: 'center',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const TotalHeaderContainer = styled(Stack)`
  position: fixed;
  background: ${colors.white};
  width: 100%;
  z-index: 100;
`;
TotalHeaderContainer.defaultProps = {
  direction: StackDirectionEnum.ROW,
};
