import { PrismaEnums } from 'enums/PrismaEnums';
import React, { useContext, useEffect } from 'react';
import { useRecoilValue } from 'recoil';
import { enumsState } from 'recoil/atoms/enumsAtom';
import { userModelState } from 'recoil/atoms/userAtoms';
import { enumsSelector } from 'recoil/selectors/enumsSelector';

const EnumContext = React.createContext<PrismaEnums>({} as PrismaEnums);

interface EnumProviderProps {
  children: React.ReactNode;
}

export default function AppEnumsProvider({ children }: Readonly<EnumProviderProps>) {
  const user = useRecoilValue(userModelState);
  const enums = useRecoilValue(enumsState);
  const requestEnums = useRecoilValue(enumsSelector);

  useEffect(() => {
    async function fetchEnums() {
      if (user && user.accessToken) {
        try {
          if (
            !Object.keys(enums).length ||
            !enums?.LeaseSecDepTypeEnum ||
            !enums.LeaseSecDepStatusEnum
          ) {
            return await requestEnums(user.accessToken);
          }
          return enums;
        } catch (_) {}
      }
    }
    fetchEnums();
  }, [user.accessToken]);

  return <EnumContext.Provider value={enums}>{children}</EnumContext.Provider>;
}

export const useAppEnums = () => {
  const value = useContext(EnumContext);

  if (!value) {
    throw new Error(`'useEnums' needs to be used with 'EnumProvider'`);
  }

  return value;
};

export const getGenericListFromEnum = (
  arrayEnums: BaseEnumType[] | object,
  translationPath: string
) => {
  const list: GenericListItem[] = [];
  if (arrayEnums && translationPath) {
    for (const [key, obj] of Object.entries(arrayEnums)) {
      if (key && obj) {
        list.push({
          id: key,
          uuid: key,
          translationKey: `${translationPath}.${key}`,
        } as GenericListItem);
      }
    }
  }
  return list;
};
