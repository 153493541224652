import { Grid, Skeleton, Stack, styled } from '@mui/material';
import colors from 'styles/colors';

export const MetricSkeletonHeaderRightRectangular = styled(Skeleton)(() => ({
  height: '4rem !important',
  width: '70%',
  marginLeft: 'auto',
}));
MetricSkeletonHeaderRightRectangular.defaultProps = {
  variant: 'rectangular',
};

export const MetricSkeletonHeader = styled(Grid)(() => ({
  padding: '0.8rem',
}));

export const ChartSkeleton = styled(Grid)(() => ({
  padding: '0.8rem',
}));

export const ChartSkeletonContainer = styled(Grid)(() => ({
  padding: '0.8rem',
}));

export const LeftChartSkeleton = styled(Skeleton)(() => ({
  width: '100%',
  height: '4rem !important',
  colors: colors.skeletonDark,
}));
LeftChartSkeleton.defaultProps = {
  variant: 'text',
};

export const LeftChartSkeleton80 = styled(LeftChartSkeleton)(() => ({
  width: '80%',
}));

export const LeftChartSkeleton60 = styled(LeftChartSkeleton)(() => ({
  width: '60%',
}));

export const LeftChartSkeleton40 = styled(LeftChartSkeleton)(() => ({
  width: '60%',
}));

export const HeaderSkeletonContainer = styled(Stack)(() => ({
  padding: '0.8rem 2.4rem 0 2.4rem',
  height: '100%',
  width: '100%',
  columnGap: '1.6rem',
}));

export const HeaderBlockSection = styled(Stack)(() => ({
  width: '10rem',
  rowGap: '0',
}));

export const HeaderTopSectionSkeleton = styled(Skeleton)(() => ({
  height: '5rem !important',
  width: '100%',
}));
HeaderTopSectionSkeleton.defaultProps = {
  variant: 'text',
};

export const HeaderBottomSectionSkeleton = styled(Skeleton)(() => ({
  height: '2rem !important',
  width: '100%',
}));
HeaderBottomSectionSkeleton.defaultProps = {
  variant: 'text',
};

export const HomeDashboardGridGrow = styled(Grid)(() => ({
  flexGrow: '1',
}));

export const HomeDashBoardLeftSection = styled(Grid)(() => ({
  flexGrow: '1',
  borderRight: `0.1rem solid ${colors.boxShadow}`,
}));

export const HomeDashboardSkeletonHeader = styled(Grid)(() => ({
  flexGrow: '0',
  borderBottom: `0.1rem solid ${colors.boxShadow}`,
  paddingBottom: '0.8rem',
}));

export const MetricSkeletonContainer = styled(Stack)(() => ({
  width: '100%',
}));

export const MetricSkeletonHeaderWrapper = styled(Grid)(() => ({
  padding: '0.8rem',
  gap: '0.8rem',
}));
MetricSkeletonHeaderWrapper.defaultProps = {
  item: true,
};

export const MetricTopSkeleton = styled(Skeleton)(() => ({
  width: '30%',
  height: '3rem !important',
}));
MetricTopSkeleton.defaultProps = {
  variant: 'text',
};

export const MetricContentLeftSkeleton = styled(Skeleton)(() => ({
  width: '90%',
  height: '4rem !important',
}));
MetricContentLeftSkeleton.defaultProps = {
  variant: 'rectangular',
};

export const SkeletonDark = styled(Skeleton)(() => ({
  colors: colors.skeletonDark,
}));
