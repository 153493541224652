import { Preferences } from 'components/pages/Home/types/Preferences';
import { useAppEnums } from 'components/providers/AppEnumsProvider';
import { CurrencyEnum } from 'enums/CurrencyEnum';
import { useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';
import { FormatNumberEnum } from 'types/user';
import { convertToFloatIfString } from 'utils/unitConverters';

export function useUserPreferences(): Preferences {
  const { UnitMeasEnum } = useAppEnums();
  const user = useRecoilValue(userModelState);

  const retVal = useMemo<Preferences>(() => {
    const { selectedUserClient } = user;
    const { client, preferredCurrencyCode } = selectedUserClient || {};
    const baseCurrency = client?.clientCurrencies.find(
      (selectedCurrency) => selectedCurrency.isBase
    );
    const selectedClientCurrency = client?.clientCurrencies.find(
      (selectedCurrency) => selectedCurrency.currencyCode === preferredCurrencyCode
    );
    const targetRate = convertToFloatIfString(selectedClientCurrency?.exchangeRate);
    const exchanges = client?.clientCurrencies;

    return {
      clientUuid: client?.clientUuid,
      baseRate: convertToFloatIfString(baseCurrency?.exchangeRate),
      targetRate,
      uom: selectedUserClient?.preferredUnitMeas || UnitMeasEnum?.sqf?.key,
      currency: selectedUserClient?.currency?.currencyCode ?? CurrencyEnum.USD,
      currencySymbol: selectedUserClient?.currency?.currencySymbol || '$',
      formatDate: selectedUserClient?.formatDate || 'dd-MM-yyyy',
      formatNumber: selectedUserClient?.formatNumber || FormatNumberEnum.PERIOD,
      baseCurrencyCurrencyCode: baseCurrency?.currencyCode || 'USD',
      exchanges,
      timezone: selectedUserClient?.timezone,
    };
  }, [user, UnitMeasEnum]);

  return retVal;
}
