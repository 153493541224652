/*
Author: Eli Elad Elrom
Component: src/utils/d3Helper.ts
*/

import * as d3 from 'd3';
import { SortDirectionEnum } from 'enums/SortDirectionEnum';

import colors, { mapValuesArray } from '../styles/colors';
import logger from './logger';

export namespace Types {
  export type Data = {
    PropertyType?: string /* bar names */;
    RentableArea?: string /* bar size */;
  };
  export type Dimensions = {
    width: number;
    height: number;
    margin: {
      left: number;
      right: number;
      top: number;
      bottom: number;
    };
    boundedWidth: number;
    boundedHeight: number;
  };
  export type Filter = {
    type?: string;
    name?: string;
  };
  export type TableData = {
    CATEGORY: string;
    LEASE_COST: number;
    RENTABLE: number;
    COST_SQ_FT: number;
    OF_TOTAL: number;
    COUNT: number;
  };
}

// @ts-ignore
export const getMaxValueBasedOnObjectProperty = (array: any[], objectName: any) =>
  Math.max(...array.map((object) => object[objectName]));

// let uniqueGroupNames = [...new Set(data.map(d => d.somePropertyOnData))];
// optionally add .sort() to the end of that line to sort the unique values
// alphabetically rather than by insertion order
// @ts-ignore
export const uniqueGroupNames = (d, accessor) => [...new Set(d.map(accessor))];

// @ts-ignore
export const uniqueNames = (data, accessor) => [...new Set(data.map((d) => d[accessor]))];

// @ts-ignore
export const uniqueNamesAsString = (data, accessor) => [
  // @ts-ignore
  ...new Set(data.map((d) => String(d[accessor]))),
];

// @ts-ignore
export const cumulativeValues = (data, accessor) => {
  let retVal = 0;
  data.forEach((dt: { [x: string]: number }) => {
    retVal = retVal + dt[accessor];
  });
  return retVal;
};

// @ts-ignore
export const uniqueValues = (dt: string[]) => [...new Set(dt)];

// @ts-ignore
export const uniqueNamesWithExtraFirstElement = (data, accessor, firstElement) =>
  // @ts-ignore
  [firstElement].concat([...new Set(data.map((d) => d[accessor]))]);

// @ts-ignore
export const extractColumnNamesFromData = (data) => data.columns.slice(1);

// @ts-ignore
export const sortListAscending = (data) => data.sort(d3.ascending);

// @ts-ignore
export const sortMultiArrayDescending = (data, propertyName) =>
  // @ts-ignore
  data.slice().sort((a, b) => d3.descending(a[propertyName], b[propertyName]));

// @ts-ignore
export const sortMultiArrayAscending = (data, propertyName) =>
  // @ts-ignore
  data.slice().sort((a, b) => d3.ascending(a[propertyName], b[propertyName]));

export const sorterByColumnAndDirection = (
  rows: Record<string, string>[],
  orderByProperty: string,
  orderDirection: SortDirectionEnum
) => {
  const orderDirectionBasicUnit = orderDirection === SortDirectionEnum.Asc ? 1 : -1;
  return [...rows].sort((rowA, rowB) =>
    String(rowA[orderByProperty] ? rowA[orderByProperty] : '').toLocaleLowerCase() >
    String(rowB[orderByProperty] ? rowB[orderByProperty] : '').toLocaleLowerCase()
      ? orderDirectionBasicUnit
      : orderDirectionBasicUnit * -1
  );
};

// @ts-ignore
export const maxValue = (data, propertyName) => d3.max(d3.map(data, (d) => d[propertyName]));

// @ts-ignore
export const minValue = (data, propertyName) => d3.min(d3.map(data, (d) => d[propertyName]));

export const generateNameLabelObject = (data: string[]) => {
  return Array.apply(null, data).map((item) => {
    return {
      value: item,
      label: item,
    };
  });
};

export const guesstimateNumberLabelWidthBasedOnConst = (number: number | string, scale: number) => {
  const varNoPoint = number.toString().replace('.', '');
  const len = varNoPoint.length;
  return len * scale;
};

export const nameWithNoSpacesOrBrackets = (datum: any) => {
  let name: string = '';
  for (const property in datum) {
    name = name + '_' + property;
    try {
      if (isNaN(datum[property])) {
        name = name + '-' + datum[property].replace(/\s/g, '-');
      }
    } catch (error) {
      return name;
    }
  }
  name = name.replace(/[\])}[{(]/g, '');
  return name;
};

export const isDimensions = (dimensions: Types.Dimensions) =>
  dimensions.hasOwnProperty('height') &&
  dimensions.hasOwnProperty('width') &&
  dimensions.hasOwnProperty('boundedWidth') &&
  dimensions.hasOwnProperty('boundedHeight') &&
  dimensions.hasOwnProperty('margin');

export const initDimensions = {
  width: 0,
  height: 0,
  margin: {
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
  boundedWidth: 0,
  boundedHeight: 0,
};

export const checkDimensionsValue = (val: any, preVal: any, property: string) => {
  let retVal = 0;
  if (val.hasOwnProperty(property)) {
    retVal = val[property];
  } else if (preVal.hasOwnProperty(property)) {
    retVal = preVal[property];
  }
  return retVal;
};

export const checkDimensionsMarginValue = (val: any, preVal: any, property: string) => {
  if (val && val.hasOwnProperty('margin') && preVal && preVal.hasOwnProperty('margin')) {
    return checkDimensionsValue(val.margin, preVal.margin, property);
  } else if (val && !preVal) {
    return val.margin[property];
  } else if (!val && preVal) {
    return preVal.margin[property];
  } else if (!val && !preVal) {
    return 0;
  }
};

export const getCurrentDimension = (
  dimensions: Types.Dimensions,
  prevDimension: Types.Dimensions
) => {
  return {
    width: checkDimensionsValue(dimensions, prevDimension, 'width'),
    height: checkDimensionsValue(dimensions, prevDimension, 'height'),
    margin: {
      left: checkDimensionsMarginValue(dimensions, prevDimension, 'left'),
      right: checkDimensionsMarginValue(dimensions, prevDimension, 'right'),
      top: checkDimensionsMarginValue(dimensions, prevDimension, 'top'),
      bottom: checkDimensionsMarginValue(dimensions, prevDimension, 'bottom'),
    },
    boundedWidth: checkDimensionsValue(dimensions, prevDimension, 'boundedWidth'),
    boundedHeight: checkDimensionsValue(dimensions, prevDimension, 'boundedHeight'),
  };
};

export const removeAllChildrenById = (name: string) =>
  d3.selectAll(`#${name}`).selectAll('*').remove();

export const percentage = (partialValue: number, totalValue: number, decimalPlaces?: number) => {
  let value: number = (100 * partialValue) / totalValue;
  if (decimalPlaces) {
    return Number(
      Math.round(parseFloat(value + 'e' + decimalPlaces)) + 'e-' + decimalPlaces
    ).toFixed(decimalPlaces);
  } else {
    return value;
  }
};

export const getUniqColor = (value: string, uniqueColorsPropertiesNames: string[]) => {
  let retVal = colors.amethystMain;
  uniqueColorsPropertiesNames.forEach((item, index) => {
    if (value === item) {
      retVal = mapValuesArray[index];
    }
  });
  return retVal;
};

// @ts-ignore
export const getYearAsInt = (year: string) => parseInt(`20${year.split('-')[2]}`);

export const linearRegression = (ySeries: number[], xSeries: number[]) => {
  const lr = {
    slope: 0,
    intercept: 0,
    r2: 0,
  };
  const n = ySeries.length;
  let sumX = 0;
  let sumY = 0;
  let sumXY = 0;
  let sumXX = 0;
  let sumYY = 0;
  for (let i = 0; i < ySeries.length; i++) {
    sumX += xSeries[i];
    sumY += ySeries[i];
    sumXY += xSeries[i] * ySeries[i];
    sumXX += xSeries[i] * xSeries[i];
    sumYY += ySeries[i] * ySeries[i];
  }
  lr.slope = (n * sumXY - sumX * sumY) / (n * sumXX - sumX * sumX);
  lr.intercept = (sumY - lr.slope * sumX) / n;
  lr.r2 = Math.pow(
    (n * sumXY - sumX * sumY) / Math.sqrt((n * sumXX - sumX * sumX) * (n * sumYY - sumY * sumY)),
    2
  );
  return lr;
};

export const cleanPropertyTitle = (str: string) =>
  str.split(' ').join('-').split('(').join('-').split(')').join('-').split('&').join('-');

export const getPercentageOfTwoNumbers = (number1: number, number2: number) => {
  const outOff = number1 + number2;
  const value = number1;
  return parseInt(((value * 100) / outOff).toString());
};

export const getShuffledArray: (array: any[]) => any[] = (array: any[]) => {
  if (array.length === 1) {
    return array;
  }
  const rand = Math.floor(Math.random() * array.length);
  return [array[rand], ...getShuffledArray(array.filter((_, i) => i != rand))];
};

export const arraysEqual = (a: string | any[], b: string | any[]) => {
  if (a === b) return true;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (a[i] !== b[i]) return false;
  }
  return true;
};

export const generateCleanPropertyName = (datum: any) => {
  let name = '';
  const regexString = /[\s:[\](){}<>!:_/@#$%^&*.,?]/g; // Regular expression to match spaces, brackets, colons, and other special characters

  for (const property in datum) {
    name = name + '_' + property;
    try {
      if (isNaN(datum[property])) {
        name = name + '-' + datum[property].replace(regexString, '-');
      }
    } catch (error) {
      logger(`nameWithNoSpaces > Error ${JSON.stringify(error)}`);
      return name;
    }
  }
  name = name.replace(/[()[\]{}]/g, ''); // Remove remaining brackets
  return name;
};
