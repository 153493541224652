import { styled, TextField } from '@mui/material';
import colors from 'styles/colors';
import { withTransientMuiProps } from 'styles/common';

export const StyledInput = styled(TextField, withTransientMuiProps)<{
  $readonly: boolean;
  height?: string;
}>`
  & input {
    height: 2.4rem !important;
    padding: 0.8rem 1.2rem 0.8rem 1.6rem;
  }
  ${(props) =>
    props.$readonly &&
    `
    & [readonly] {
      cursor: default;
      background: ${colors.lightGrey} !important;
      ${props.height ? `height: ${props.height};` : ''}
    }
    &:not([readonly]) .MuiInputLabel-root.Mui-focused {
    color: ${colors.greyLabel};
  }
    &:not([readonly]) .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 0.1rem solid ${colors.borderGray};
  }
  `}
`;
