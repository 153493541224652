import { Box, TableCell, TableRow } from '@mui/material';
import { styled } from '@mui/material/styles';

interface StyledTableCellProps {
  cellverticalpadding?: string | number; // all lower case to avoid css errors
}

export const StyledTableCell = styled(TableCell)(
  ({ cellverticalpadding = '0.6rem' }: StyledTableCellProps) => ({
    paddingTop: cellverticalpadding,
    paddingBottom: cellverticalpadding,
    align: 'left',
  })
);

export const StyledTableRow = styled(TableRow)`
  height: auto;
`;

export const StyledWrapper = styled(Box)`
  width: 100%;
  overflow: 'hidden';
`;
