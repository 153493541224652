import { Checkbox, FormControlLabel } from '@mui/material';
import { FormikValues } from 'formik';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface CheckboxInputProps {
  formik: FormikValues;
  name: string;
  label: string;
}

export const CheckboxInput = ({ name, label, formik }: CheckboxInputProps) => {
  const { t } = useTranslation();

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    formik.setFieldTouched(name, true, false);
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          value={formik.values[name]}
          name={name}
          size="small"
          onChange={handleCheckboxChange}
          checked={formik.values[name]}
        />
      }
      label={t(label)}
      labelPlacement="end"
    />
  );
};
