import { ChipProps } from '@mui/material';
import { ChipColor } from 'enums/ChipColorEnum';

import { StyledChip } from './ChipComponent.css';

export interface ChipComponentProps extends ChipProps {
  label: string;
  chipSize?: number;
  minWidth?: number;
  maxWidth?: number;
  chipColor?: ChipColor;
}

export function ChipComponent({
  label,
  chipColor,
  icon,
  chipSize,
  minWidth,
  maxWidth,
}: ChipComponentProps) {
  return (
    <StyledChip
      label={label}
      $chipColor={chipColor}
      icon={icon}
      variant="outlined"
      $chipSize={chipSize}
      $minWidth={minWidth}
      $maxWidth={maxWidth}
      size="small"
    />
  );
}
