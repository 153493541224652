import { Autocomplete, AutocompleteChangeReason, AutocompleteProps } from '@mui/material';
import { FormikValues } from 'formik';
import { useTranslation } from 'next-i18next';
import React, { SyntheticEvent } from 'react';

import { TextFieldStyled } from './Autocomplete.css';

interface ExtendedAutocompleteProps<TValue>
  extends AutocompleteProps<TValue, undefined, undefined, boolean> {
  name: string;
}

interface Option {
  id?: string;
  uuid: string;
  name: string;
}

interface AutocompleteInputProps<TValue extends Option>
  extends Omit<ExtendedAutocompleteProps<TValue>, 'renderInput' | 'getOptionLabel' | 'tabIndex'> {
  label: string;
  name: string;
  formik: FormikValues;
  options: TValue[];
  settingKeyName: keyof Omit<Option, 'id'>;
  startIcon?: React.ReactNode;
  tabIndex?: number;
  onChangeHandler?: (v: string | TValue | null) => void;
  placeholder?: string;
}
// settingKeyName prop in the AutocompleteInput component is used to determine which key of the Option object should be used to pass the data to the form.
export const AutocompleteInput = <TValue extends Option>({
  label,
  name,
  formik,
  options,
  settingKeyName,
  startIcon,
  tabIndex,
  onChangeHandler,
  ...rest
}: AutocompleteInputProps<TValue>) => {
  const { t } = useTranslation();
  const defaultValue =
    options.find((option) => option[settingKeyName] === formik.values[name]) || null;
  const handleChange = (
    _: SyntheticEvent<Element, Event>,
    value: string | TValue | null,
    _r: AutocompleteChangeReason
  ) => {
    const selectedValue = value && typeof value !== 'string' ? value[settingKeyName] : value || '';
    formik.setFieldValue(name, selectedValue);
    onChangeHandler?.(value);
    formik.setFieldTouched(name, true, false);
  };

  const getOptionLabel = (option: string | TValue) => {
    if (typeof option === 'string') {
      return option;
    }
    return option.name;
  };

  return (
    // @ts-ignore
    <Autocomplete
      {...rest}
      id={name as string}
      autoHighlight
      options={options}
      getOptionLabel={getOptionLabel}
      value={defaultValue}
      clearOnEscape={true}
      onChange={handleChange}
      renderInput={(params) => (
        <TextFieldStyled
          {...params}
          label={t(label)}
          size="small"
          error={formik.touched[name] && Boolean(formik.errors[name])}
          helperText={formik.touched[name] && formik.errors[name]}
          placeholder={t(rest.placeholder as string)}
          InputProps={
            startIcon
              ? {
                  ...params.InputProps,
                  startAdornment: (
                    <>
                      {startIcon}
                      {params.InputProps.startAdornment}
                    </>
                  ),
                }
              : { ...params.InputProps }
          }
          inputProps={{ ...params.inputProps, tabIndex: tabIndex || undefined }}
        />
      )}
    />
  );
};
