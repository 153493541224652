import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbAltIcon from '@mui/icons-material/DoDisturbAlt';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useAppEnums } from 'components/providers/AppEnumsProvider';
import { ChipComponent, ChipComponentProps } from 'components/ui/chip/ChipComponent/ChipComponent';
import { TBasePaths } from 'constants/translations';
import { ChipColor } from 'enums/ChipColorEnum';
import { ClauseStatusEnumType } from 'enums/PrismaEnums';
import { useTranslation } from 'next-i18next';
import { memo } from 'react';

function checkClauseLabel(clauseStatusEnum: ClauseStatusEnumType, label: string) {
  const enumKeys: string[] = Object.values(clauseStatusEnum).map((item) => item?.key);

  const isKnownClauseLabel = Object.values<string>(enumKeys).includes(label);
  return isKnownClauseLabel;
}

function ClauseChipComponent({ label, chipSize, minWidth, maxWidth }: ChipComponentProps) {
  const { t } = useTranslation();
  const { ClauseStatusEnum } = useAppEnums();

  const { exercised, active, declined, cancelled } = ClauseStatusEnum;

  const clauseChipIconMap = {
    [exercised?.key]: <CheckCircleIcon fontSize="small" />,
    [active?.key]: <RemoveCircleIcon fontSize="small" />,
    [declined?.key]: <CancelIcon fontSize="small" />,
    [cancelled?.key]: <DoDisturbAltIcon fontSize="small" />,
  };

  const clauseChipColorMap: Record<string, ChipColor> = {
    [exercised?.key]: ChipColor.Success,
    [active?.key]: ChipColor.Periwinkle,
    [declined?.key]: ChipColor.Error,
    [cancelled?.key]: ChipColor.Default,
  };

  const isKnownClauseLabel = checkClauseLabel(ClauseStatusEnum, label);

  if (isKnownClauseLabel) {
    const clauseIcon = clauseChipIconMap[label];
    const clauseColor = clauseChipColorMap[label];
    return (
      <ChipComponent
        label={t(`${TBasePaths.PA_ENUM}.clauseStatus.${label}`)}
        icon={clauseIcon}
        chipSize={chipSize}
        minWidth={minWidth}
        maxWidth={maxWidth}
        chipColor={clauseColor}
      />
    );
  }

  return (
    <ChipComponent
      label={t(`${TBasePaths.PA_ENUM}.clauseStatus.${label}`)}
      chipSize={chipSize}
      chipColor={ChipColor.Default}
      minWidth={minWidth}
      maxWidth={maxWidth}
    />
  );
}

export const ClauseChip = memo(ClauseChipComponent);
