import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from 'styles/colors';

export const DetailRowContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'space-between',
  gap: '1.5rem',
}));

export const RowTitle = styled(Typography)(() => ({
  color: colors.midnightLight,
  wordBreak: 'break-word',
  flex: '0 0 40%',
  lineHeight: '2rem',
  textAlign: 'right',
}));

export const RowValue = styled(Typography)(() => ({
  flex: '0 0 60%',
}));
