import { PrismaEnums } from 'enums/PrismaEnums';
import { atom } from 'recoil';
import { recoilPersist } from 'recoil-persist';

export const ENUMS_STORAGE_KEY = 'enums-state';

const sessionStorage = typeof window !== `undefined` ? window.sessionStorage : undefined;

const { persistAtom } = recoilPersist({
  key: ENUMS_STORAGE_KEY,
  storage: sessionStorage,
});

export const enumsState = atom<PrismaEnums>({
  key: 'EnumsState',
  default: {} as PrismaEnums,
  effects: [persistAtom],
});
