import { LeaseEntity } from 'entities/Lease/Lease';
import { atom, atomFamily } from 'recoil';
import {
  GetLeasesWithSpaceFinancialsParams,
  LeaseProperties,
  LeaseWithFinancialsResponse,
} from 'types/lease';
import { v4 as uuid } from 'uuid';

import { getLeaseByIdSelector } from '../selectors/leaseSelectors';

export const leasesState = atomFamily<LeaseEntity[], string>({
  key: 'LeasesState',
  default: [],
});

export const leasesWithSpaceFinancialsResetState = atom({
  key: 'LeasesWithSpaceFinancialsRequestIDState',
  default: uuid(),
});

export const leasesWithSpaceFinancialsState = atomFamily<
  LeaseWithFinancialsResponse,
  GetLeasesWithSpaceFinancialsParams
>({
  key: 'LeasesWithSpaceFinancialsState',
  default: { leases: [], timeArray: [] },
});

export const leaseByIdState = atomFamily<LeaseEntity | undefined, string>({
  key: 'LeaseByIdState',
  default: (leaseUuid) => getLeaseByIdSelector(leaseUuid),
});

export const leasesByPropertyState = atomFamily<LeaseProperties[], string>({
  key: 'LeasesByPropertyState',
  default: [],
});
