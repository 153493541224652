import { styled, TextField } from '@mui/material';

export const TextFieldStyled = styled(TextField)(() => ({
  '& .MuiInputBase-input': {
    padding: '0 !important',
    height: ' 2.4rem',
  },
  '& .MuiOutlinedInput-root': {
    padding: '0.8rem 1.2rem 0.8rem 1.6rem !important',
  },
}));
