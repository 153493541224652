import {
  FormControl,
  FormControlProps,
  FormHelperText,
  InputLabel,
  InputLabelProps,
  MenuItem,
  Select,
  SelectProps,
} from '@mui/material';
import { useTranslation } from 'next-i18next';
import { FieldError } from 'react-hook-form';

type SelectItemType = GenericListItem;
interface SelectByUuidProps {
  items: SelectItemType[];
  value: SelectProps<string>['value'];
  onChange: SelectProps<string>['onChange'];
  inputRef: SelectProps['inputRef'];
  label: SelectProps['label'];
  error?: FieldError;
  required: FormControlProps['required'];
  dataTestId?: string;
  name?: SelectProps['name'];
  autoComplete?: string;
}

export const SelectByUuid = ({
  items = [],
  value,
  onChange,
  inputRef,
  label,
  error,
  required,
  dataTestId,
  name,
  autoComplete,
}: SelectByUuidProps) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth size="small" required={required} error={!!error}>
      <InputLabel required={required} htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        value={value || ''}
        label={label}
        onChange={onChange}
        inputRef={inputRef}
        size="small"
        sx={{ height: '3.8rem' }}
        inputProps={{ 'data-testid': dataTestId, autoComplete: autoComplete }}
        autoComplete="off"
        name={name}
      >
        {items.map((item) => {
          if (item.deletedAt) return null;
          return (
            <MenuItem value={item.uuid} key={item.uuid}>
              {t(item.translationKey)}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};

interface SelectByNameProps {
  items: SelectItemType[];
  value: SelectProps<string>['value'];
  onChange: SelectProps<string>['onChange'];
  onClose?: SelectProps['onClose'];
  inputRef: SelectProps['inputRef'];
  label: SelectProps['label'];
  error?: FieldError;
  required: FormControlProps['required'];
  dataTestId?: string;
  inputLabelProps?: InputLabelProps['sx'];
  name?: SelectProps['name'];
  autoComplete?: string;
}

export const SelectByName = ({
  items = [],
  value,
  onChange,
  inputRef,
  label,
  error,
  required,
  dataTestId,
  inputLabelProps,
  name,
  autoComplete,
  onClose,
}: SelectByNameProps) => {
  const { t } = useTranslation();
  return (
    <FormControl fullWidth size="small" required={required} error={!!error}>
      <InputLabel required={required} sx={inputLabelProps} htmlFor={name}>
        {label}
      </InputLabel>
      <Select
        value={value || ''}
        label={label}
        onChange={onChange}
        inputRef={inputRef}
        size="small"
        autoComplete={autoComplete}
        inputProps={{ 'data-testid': dataTestId, autoComplete: autoComplete }}
        sx={{ height: '3.8rem' }}
        name={name}
        onClose={onClose}
      >
        {items.map((item) => {
          if (item.deletedAt) return null;
          return (
            <MenuItem value={item.uuid} key={item.uuid}>
              {t(item.translationKey)}
            </MenuItem>
          );
        })}
      </Select>
      {error && <FormHelperText>{error?.message}</FormHelperText>}
    </FormControl>
  );
};
