import { IActivityEventLogField } from 'types/activity';

export interface IPropertyItem {
  propertyName: string;
  updateKey: string | null;
  oldValue: string | null | number | undefined;
  newValue: string | null | number | undefined;
}

export const filterDetails = (item: IActivityEventLogField): boolean => {
  const propertiesToRemove = [
    'createdAt',
    'updatedAt',
    'deletedAt',
    'uuid',
    'leaseUuid',
    'propertyUuid',
    'leaseContactUuid',
    'parentLeaseUuid',
    'costSchedCategoryUuid',
    'costSchedUuid',
    'clauseUuid',
    'contactUuid',
    'responsibilityUuid',
    'lease',
    'planOfActionUuid',
    'leaseCategoryUuid',
  ];
  return !propertiesToRemove.includes(item.field);
};

export const getFilteredActivityLogCount = (eventLogs: IActivityEventLogField[]) => {
  try {
    const detailsFiltered = eventLogs.filter(filterDetails);
    return detailsFiltered.length;
  } catch (e) {
    return 0;
  }
};

export const updatePropertyIfChanged = (
  updatedValues: Record<string, any>,
  item: IPropertyItem
) => {
  const { newValue, oldValue, propertyName, updateKey } = item;

  if (newValue !== oldValue) {
    const updatedObject = {
      ...updatedValues,
      [propertyName]: newValue,
    };

    if (updateKey !== null) {
      updatedObject[updateKey] = oldValue;
    }

    return updatedObject;
  }
  return updatedValues;
};
