import { StackDirectionEnum } from 'enums/StackDirectionEnum';
import React from 'react';
import {
  ChartSkeletonContainer,
  HeaderSkeletonContainer,
  HomeDashboardGridGrow,
  HomeDashBoardLeftSection,
  HomeDashboardSkeletonHeader,
  LeftChartSkeleton,
  LeftChartSkeleton80,
  LeftChartSkeleton60,
  LeftChartSkeleton40,
  MetricSkeletonContainer,
  MetricSkeletonHeader,
  MetricSkeletonHeaderRightRectangular,
  MetricSkeletonHeaderWrapper,
  HeaderTopSectionSkeleton,
  MetricTopSkeleton,
  MetricContentLeftSkeleton,
  HeaderBlockSection,
  HeaderBottomSectionSkeleton,
} from './HomeDashboardGraphSkeletonLoader.css';

function ChartSkeleton() {
  return (
    <ChartSkeletonContainer container direction={StackDirectionEnum.ROW}>
      <ChartSkeletonContainer item xs={4} gap={1}>
        <LeftChartSkeleton />
        <LeftChartSkeleton />
        <LeftChartSkeleton />
        <LeftChartSkeleton />
      </ChartSkeletonContainer>
      <ChartSkeletonContainer item xs={8} gap={1}>
        <LeftChartSkeleton />
        <LeftChartSkeleton80 />
        <LeftChartSkeleton60 />
        <LeftChartSkeleton40 />
      </ChartSkeletonContainer>
    </ChartSkeletonContainer>
  );
}

function MetricSkeleton() {
  return (
    <MetricSkeletonContainer direction={StackDirectionEnum.COLUMN}>
      {/* Filter */}
      <MetricSkeletonHeader container direction={StackDirectionEnum.ROW}>
        <MetricSkeletonHeaderWrapper xs={12}>
          <MetricTopSkeleton />
        </MetricSkeletonHeaderWrapper>
        <MetricSkeletonHeaderWrapper xs={6}>
          <MetricContentLeftSkeleton />
        </MetricSkeletonHeaderWrapper>
        <MetricSkeletonHeaderWrapper xs={6}>
          <MetricSkeletonHeaderRightRectangular />
        </MetricSkeletonHeaderWrapper>
      </MetricSkeletonHeader>
      {/* chart */}
      <ChartSkeleton />
    </MetricSkeletonContainer>
  );
}

function HeaderSkeleton() {
  const blocks = Array.from({ length: 4 }, (_, i) => i);
  return (
    <HeaderSkeletonContainer direction={StackDirectionEnum.ROW}>
      {blocks.map((i) => (
        <HeaderBlockSection key={i} direction={StackDirectionEnum.COLUMN}>
          <HeaderTopSectionSkeleton />
          <HeaderBottomSectionSkeleton />
        </HeaderBlockSection>
      ))}
    </HeaderSkeletonContainer>
  );
}

export function HomeDashboardGraphSkeletonLoader() {
  return (
    <HomeDashboardGridGrow container direction={StackDirectionEnum.COLUMN}>
      <HomeDashboardSkeletonHeader container direction={StackDirectionEnum.ROW}>
        <HeaderSkeleton />
      </HomeDashboardSkeletonHeader>

      <HomeDashboardGridGrow container direction={StackDirectionEnum.ROW}>
        <HomeDashBoardLeftSection item xs={6}>
          <MetricSkeleton />
        </HomeDashBoardLeftSection>
        <HomeDashboardGridGrow item xs={6} flexGrow={1}>
          <ChartSkeleton />
        </HomeDashboardGridGrow>
      </HomeDashboardGridGrow>
    </HomeDashboardGridGrow>
  );
}
