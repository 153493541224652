import { GridColumnVisibilityModel } from '@mui/x-data-grid/hooks/features/columns/gridColumnsInterfaces';
import { GridDensity } from '@mui/x-data-grid/models/gridDensity';
import { GridFilterModel } from '@mui/x-data-grid/models/gridFilterModel';
import colors from 'styles/colors';

import {
  BottomMainWidgetsTypeEnum,
  MainWidgetsTypeEnum,
  RightWidgetsTypeEnum,
  SelectedMainHeaderTypeEnum,
} from '../components/pages/DashboardPage/WidgetsType';
import { UserEntity } from '../entities/User';

export const TOP_HEADER_HEIGHT = 125;
export const RIGHT_SIDE_FILTERS_FIXED_WIDTH = 248;
export const COLLAPSE_BOTTOM_HEIGHT = 34;
export const MARKET_INTELLIGENT_HEIGHT = 210;
export const MIN_X_SCATTER_CHART_LEFT = 150;
export const CRITICAL_DATE_HEADER = '12rem';
export const HEATMAP_TOP_GAP = 168;
export const DETAIL_FOOTER_HEIGHT = '7.2rem';

// Headers
export const SITE_HEADER_HEIGHT = '6.2rem';
export const DASHBOARD_HEADER_HEIGHT = '5rem';
export const HEADER_WIDGET = 40;

export const DASHBOARD_HEADER_HEIGHT_IN_PIXELS = 219;
export const SECOND_TOP_HEADER_HEIGHT = 215;
export const MOR_HEADER_HEIGHT = 280;
export const METRICS_HEADER_HEIGHT = 200;

export const FILTERING_TABLE_BUTTONS = '3.825rem';
export const REPORT_CONTAINER_HEIGHT = '12rem';
export const CRITICAL_HEADER = '14.75rem';
export const LEFT_NAVBAR_WIDTH = 168;
export const LEFT_NAVBAR_WIDTH_COLLAPSED = 65;
export const SITE_HEADER_HEIGHT_PX = 65;
export const LEASE_HEADER = 201;
export const HOME_METRIC_HEADER = '7.05rem';
export const DRAWER_WIDTH = 157;
export const DRAWER_WIDTH_CLOSED = 52;
export const ADMIN_HEADER = '13.3rem';
export const IMAGE_WIDTH = '25rem';
export const IMAGE_HEIGHT = '18.75rem';

export const BOX_SHADOW = `0 0.2rem 0.1rem -0.1rem ${colors.smokeShadow},
    0 0.1rem 0.1rem ${colors.mistyBlackShadow}, 0 0.1rem 0.3rem ${colors.subtleShadow};`;

export const BOX_SHADOW_SPREADED = `0 0.3rem 0.5rem -0.1rem ${colors.smokeShadow}`;

// -------------------------------------- Bottom Widget ----------------------------------------------

export const getShowBottomBorder = (selectedMainWidget?: string | null) => {
  const retVal = selectedMainWidget === MainWidgetsTypeEnum.LEASE_EXPIRATIONS_DASHBOARD;
  return retVal;
};

// -------------------------------------- Main Widget ----------------------------------------------

export const additionalPaddingByWidgetType = (widgetType: string) => {
  let retVal = 0;
  switch (widgetType) {
    case RightWidgetsTypeEnum.PORTFOLIO_OVERVIEW_BAR_CHART:
      retVal = 7;
      break;
    case MainWidgetsTypeEnum.SCATTER_REMAINING_MONTHS_TO_EXPIRATION:
      retVal = 15;
      break;
    case MainWidgetsTypeEnum.MAP_BOX:
      retVal = 90;
      break;
  }
  return retVal;
};

// DATUM

export interface TableSettings {
  id: string;
  columns?: GridColumnVisibilityModel;
  density?: GridDensity;
  filters?: GridFilterModel;
}

export interface UserModel {
  isAuth: boolean | undefined;
  activeClientUuid: string;
  isAdmin: boolean;
  oktaUserId: string;
  userName: string;
  firstName: string;
  lastName: string;
  userUuid: string;
  email: string;
  layoutTopLeftWidth: number;
  layoutRightTopRow: number;
  layoutWidgetsSectionHeight: number;
  layoutBottomWidgetHeight: number;
  tableSettings: TableSettings[];
  pageTitle: string;
  scale: number;
  selectedItem: any;
  originatedComponent: string;
  dashboardGalleryGridView: boolean;
  accessToken?: string;
  oktaClientId?: string;
  userInitial?: string;
  isFirstSignIn?: boolean;

  clientDashboardBookmarkModels: [];
  clientDashboardModels: [];
  selectedUserClientModel?: any;
  selectedClientDashboardModel: any;
  userClientModels: [];
}

export const getRightWidgetWidth = (_userModel: UserEntity, _width: number) => {
  return 0;
};

export const getRightWidgetCollapseWidth = (_userModel: UserEntity) => {
  return 50;
};

export const getMainWidgetWidth = (
  screenWidth: number,
  userModel: UserEntity,
  filterCollapse?: boolean,
  isRightWidgetCollapse?: boolean
) => {
  const filterCollapseWidth = filterCollapse ? 0 : RIGHT_SIDE_FILTERS_FIXED_WIDTH;
  const rightWidgetWidth = isRightWidgetCollapse
    ? getRightWidgetCollapseWidth(userModel)
    : getRightWidgetWidth(userModel, screenWidth);
  return screenWidth - filterCollapseWidth - rightWidgetWidth;
};

export const getBottomWidgetHeight = (userModel: UserModel, isBottomCollapse?: boolean) => {
  const selectedDashboard = userModel.selectedClientDashboardModel?.dashboardModel;
  let bottomWidgetHeight = 0;
  if (selectedDashboard?.selectedMainBottomType !== BottomMainWidgetsTypeEnum.NONE) {
    switch (selectedDashboard?.selectedMainBottomType) {
      case BottomMainWidgetsTypeEnum.LEASE_REMAINING_MONTHS_TO_EXPIRATION:
        bottomWidgetHeight = isBottomCollapse ? COLLAPSE_BOTTOM_HEIGHT : 200;
        break;
      case BottomMainWidgetsTypeEnum.BOTTOM_PROPERTY_COST_PARETO:
        bottomWidgetHeight = isBottomCollapse ? COLLAPSE_BOTTOM_HEIGHT - 88 : 200;
        break;
    }
  }
  return bottomWidgetHeight;
};

export const getMainWidgetsHeight = (
  screenHeight: number,
  userModel: any,
  headerHeight?: number | undefined,
  isBottomCollapse?: undefined | boolean
) => {
  const header = headerHeight ?? SECOND_TOP_HEADER_HEIGHT;
  const bottomHeight = getBottomWidgetHeight(userModel, isBottomCollapse);
  return screenHeight - header - bottomHeight;
};

export const getDashboardMetricsHeaderHeightByDashboard = (
  userModel: UserModel,
  widgetType?: string | null
) => {
  let metricsHeaderHeight = TOP_HEADER_HEIGHT;
  if (
    userModel.selectedClientDashboardModel?.dashboardModel?.selectedMainHeader !==
    SelectedMainHeaderTypeEnum.NONE
  ) {
    switch (userModel.selectedClientDashboardModel?.dashboardModel?.selectedMainHeader) {
      case SelectedMainHeaderTypeEnum.MOR_HEADER:
        if (widgetType === RightWidgetsTypeEnum.MARKET_TO_MARKET_BAR_CHART)
          metricsHeaderHeight = MOR_HEADER_HEIGHT - 45;
        else {
          metricsHeaderHeight = MOR_HEADER_HEIGHT;
        }
        break;
      case SelectedMainHeaderTypeEnum.METRICS_HEADER:
        metricsHeaderHeight = METRICS_HEADER_HEIGHT;
        break;
      case SelectedMainHeaderTypeEnum.LEASE_METRICS_HEADER:
        metricsHeaderHeight = MARKET_INTELLIGENT_HEIGHT;
        break;
      case null:
        metricsHeaderHeight = 0;
        break;
    }
  }
  return metricsHeaderHeight;
};
