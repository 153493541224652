import { ToggleButton as MuiToggleButton } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  '&.MuiButtonBase-root': {
    padding: '0.6rem 1.2rem',
    borderColor: theme.palette.secondary.light,
    color: theme.palette.secondary.light,
  },

  '&.MuiButtonBase-root.Mui-selected': {
    backgroundColor: theme.palette.secondary.dark,
    borderColor: theme.palette.secondary.dark,
    color: theme.palette.primary.extraLight,
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
}));
