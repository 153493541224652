import { HeaderStackedCell } from './HomeDashboardGraphTotalHeaderCell';
import { TotalHeaderContainer } from './HomeDashboardGraphTotalHeader.css';

interface TotalHeaderProps {
  cells: { label: string; value: string }[];
}

const TotalHeader = ({ cells }: TotalHeaderProps) => {
  return (
    <TotalHeaderContainer>
      {cells.map((cell) => (
        <HeaderStackedCell key={cell.label} label={cell.label.toUpperCase()} value={cell.value} />
      ))}
    </TotalHeaderContainer>
  );
};

export default TotalHeader;
