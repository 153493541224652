import { StackDirectionEnum } from 'enums/StackDirectionEnum';

import {
  CardSkeletonBottom,
  CardSkeletonStack,
  CardSkeletonTop,
  ListSkeletonLoader,
} from './HomeDashboardListSkeletonLoader.css';

function CardSkeleton() {
  return (
    <CardSkeletonStack direction={StackDirectionEnum.COLUMN}>
      <CardSkeletonTop />
      <CardSkeletonBottom />
    </CardSkeletonStack>
  );
}

export function HomeDashboardListSkeletonLoader({ rows = 4 }: Readonly<{ rows?: number }>) {
  const cards = Array.from({ length: rows }, (_, i) => i);
  return (
    <ListSkeletonLoader direction={StackDirectionEnum.COLUMN}>
      {cards.map((i) => (
        <CardSkeleton key={i} />
      ))}
    </ListSkeletonLoader>
  );
}
