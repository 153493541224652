import {
  HeaderCellContainer,
  HeaderCellLabel,
  HeaderCellValue,
} from './HomeDashboardGraphTotalHeader.css';

interface HeaderStackedCellProps {
  label: string;
  value: string;
}

export const HeaderStackedCell = ({ label, value }: HeaderStackedCellProps) => {
  return (
    <HeaderCellContainer>
      <HeaderCellValue>{value}</HeaderCellValue>
      <HeaderCellLabel>{label}</HeaderCellLabel>
    </HeaderCellContainer>
  );
};
