/*

Home page Number of # LEASES we should see on the header via SQL;
=================================================================

WITH constants AS (SELECT '378db740-7041-4eaf-97e2-cb4002025c05'::UUID AS client_uuid,
                          'USD'                                        AS currency_code_user,
                          'SQF'                                        AS unit_meas_user,
                          10.76391041671                               AS sqm_to_sqf_conversion,
                          0.092903                                     AS sqf_to_sqm_conversion)
SELECT p.property_uuid,
       p.property_id,
       p.property_name,
       mm.market_metro_name,
       ctry.country_name,
       r.subregion_name,
       r.region_name,
       pt.property_type_name,
       pt.property_subtype_name,
       l.lease_uuid,
       l.lease_id,
       l.lease_name,
       lt.lease_type_name,
       lat.lease_area_type_name,
       lb.lease_business_name,
       COALESCE(
           ROUND(lt.lease_type_pos_neg * (CASE
                                            WHEN LOWER(const.unit_meas_user) = 'sqf'
                                              THEN CASE
                                                     WHEN l.unit_meas = 'sqm'
                                                       THEN lsr.rent_area * const.sqm_to_sqf_conversion
                                                     ELSE lsr.rent_area END
                                            WHEN LOWER(const.unit_meas_user) = 'sqm'
                                              THEN CASE
                                                     WHEN l.unit_meas = 'sqf'
                                                       THEN lsr.rent_area * const.sqf_to_sqm_conversion
                                                     ELSE lsr.rent_area END END),
                 0), 0)         AS rent_area_net_user,


       const.unit_meas_user     AS unit_meas_user,
       sql_period_amt.period_amt_lease_net_user,
       const.currency_code_user AS currency_code_user
FROM client c
       INNER JOIN constants const ON true
       INNER JOIN property p ON c.client_uuid = p.client_uuid
       INNER JOIN region r ON p.region_uuid = r.region_uuid
       INNER JOIN country ctry ON p.country_uuid = ctry.country_uuid
       INNER JOIN market_metro mm ON p.market_metro_uuid = mm.market_metro_uuid
       INNER JOIN property_type pt ON p.property_type_uuid = pt.property_type_uuid
       INNER JOIN lease l ON p.property_uuid = l.property_uuid
       INNER JOIN lease_type lt ON l.lease_type_uuid = lt.lease_type_uuid
       INNER JOIN lease_area_type lat ON l.lease_area_type_uuid = lat.lease_area_type_uuid
       LEFT JOIN lease_business lb ON l.lease_business_uuid = lb.lease_business_uuid
       INNER JOIN (SELECT ls.lease_uuid, SUM(ls.rent_area) AS rent_area
                   FROM lease_space ls
                          INNER JOIN lease l ON ls.lease_uuid = l.lease_uuid
                   WHERE ls.deleted_at IS NULL
                   GROUP BY ls.lease_uuid) AS lsr ON l.lease_uuid = lsr.lease_uuid
       LEFT JOIN (SELECT cs.lease_uuid,
                         COALESCE(ROUND(SUM((cc_user.exchange_rate / cc_local.exchange_rate) * cd.period_amt *
                                            csc.amt_pos_neg), 4), 0)
                           AS period_amt_lease_net_user
                  FROM cost_sched cs
                         INNER JOIN constants const ON true
                         INNER JOIN cost_data cd ON cs.cost_sched_uuid = cd.cost_sched_uuid
                         INNER JOIN cost_sched_category csc
                                    ON cs.cost_sched_category_uuid = csc.cost_sched_category_uuid
                         INNER JOIN client c ON c.client_uuid = const.client_uuid
                         INNER JOIN lease l ON l.lease_uuid = cs.lease_uuid
                         INNER JOIN client_currency cc_local
                                    ON cc_local.client_uuid = const.client_uuid AND
                                       cs.currency_code = cc_local.currency_code
                         INNER JOIN client_currency cc_user ON l.client_uuid = cc_user.client_uuid AND
                                                               cc_user.currency_code = const.currency_code_user
                  WHERE cs.deleted_at IS NULL
                    AND DATE_PART('year', cd.period_date) = c.report_yr
                  GROUP BY cs.lease_uuid) AS sql_period_amt ON l.lease_uuid = sql_period_amt.lease_uuid
where c.client_uuid = const.client_uuid
  AND p.deleted_at IS NULL
  AND l.deleted_at IS NULL
  AND p.property_status = 'active'
  AND l.lease_status = 'active';


Breakdown of leases and each cost schedule / cost data items
============================================================

select
l.lease_uuid,
l.lease_name,
cd.period_date,
cd.period_amt,
cs.currency_code as cc_code_source,
cc.exchange_rate as exchange_rate,
(1/cc.exchange_rate)* cd.period_amt as period_amount_extend,
l.client_uuid
from
property p
inner join lease l on p.property_uuid = l.property_uuid
inner join lease_space ls on l.lease_uuid = ls.lease_uuid
left join cost_sched cs on ls.lease_space_uuid = cs.lease_space_uuid
left join client_currency cc on cs.currency_code = cc.currency_code and cc.client_uuid = '378db740-7041-4eaf-97e2-cb4002025c05'
left join cost_data cd on cs.cost_sched_uuid = cd.cost_sched_uuid
where l.client_uuid = '378db740-7041-4eaf-97e2-cb4002025c05'
and p.deleted_at is null and p.property_status = 'active'
and l.deleted_at is null and l.lease_status = 'active'
and DATE_PART('year', cd.period_date) = 2024
order by l.lease_uuid;

SQL for total lease that includes all cost schedule / cost data items and amount period value converted
=======================================================================================================

-- Replace constants values with your data

WITH constants AS (SELECT 'ac5c7e29-6143-4e0f-ad76-bc9613b40714'::UUID AS client_uuid,
                          'USD'                                        AS currency_code_user,
                          'SQF'                                        AS unit_meas_user,
                          10.76391041671                               AS sqm_to_sqf_conversion,
                          0.092903                                     AS sqf_to_sqm_conversion)
SELECT p.property_uuid,
       p.property_id,
       p.property_name,
       mm.market_metro_name,
       ctry.country_name,
       r.subregion_name,
       r.region_name,
       pt.property_type_name,
       pt.property_subtype_name,
       l.lease_uuid,
       l.lease_id,
       l.lease_name,
       lt.lease_type_name,
       lat.lease_area_type_name,
       lb.lease_business_name,
       COALESCE(
           ROUND(lt.lease_type_pos_neg * (CASE
                                            WHEN LOWER(const.unit_meas_user) = 'sqf'
                                              THEN CASE
                                                     WHEN l.unit_meas = 'sqm'
                                                       THEN lsr.rent_area * const.sqm_to_sqf_conversion
                                                     ELSE lsr.rent_area END
                                            WHEN LOWER(const.unit_meas_user) = 'sqm'
                                              THEN CASE
                                                     WHEN l.unit_meas = 'sqf'
                                                       THEN lsr.rent_area * const.sqf_to_sqm_conversion
                                                     ELSE lsr.rent_area END END),
                 0), 0)         AS rent_area_net_user,


       const.unit_meas_user     AS unit_meas_user,
       sql_period_amt.period_amt_lease_net_user,
       const.currency_code_user AS currency_code_user
FROM client c
       INNER JOIN constants const ON true
       INNER JOIN property p ON c.client_uuid = p.client_uuid
       INNER JOIN region r ON p.region_uuid = r.region_uuid
       INNER JOIN country ctry ON p.country_uuid = ctry.country_uuid
       INNER JOIN market_metro mm ON p.market_metro_uuid = mm.market_metro_uuid
       INNER JOIN property_type pt ON p.property_type_uuid = pt.property_type_uuid
       INNER JOIN lease l ON p.property_uuid = l.property_uuid
       INNER JOIN lease_type lt ON l.lease_type_uuid = lt.lease_type_uuid
       INNER JOIN lease_area_type lat ON l.lease_area_type_uuid = lat.lease_area_type_uuid
       LEFT JOIN lease_business lb ON l.lease_business_uuid = lb.lease_business_uuid
       INNER JOIN (SELECT ls.lease_uuid, SUM(ls.rent_area) AS rent_area
                   FROM lease_space ls
                          INNER JOIN lease l ON ls.lease_uuid = l.lease_uuid
                   WHERE ls.deleted_at IS NULL
                   GROUP BY ls.lease_uuid) AS lsr ON l.lease_uuid = lsr.lease_uuid
       LEFT JOIN (SELECT cs.lease_uuid,
                         COALESCE(ROUND(SUM((cc_user.exchange_rate / cc_local.exchange_rate) * cd.period_amt *
                                            csc.amt_pos_neg), 4), 0)
                           AS period_amt_lease_net_user
                  FROM cost_sched cs
                         INNER JOIN constants const ON true
                         INNER JOIN cost_data cd ON cs.cost_sched_uuid = cd.cost_sched_uuid
                         INNER JOIN cost_sched_category csc
                                    ON cs.cost_sched_category_uuid = csc.cost_sched_category_uuid
                         INNER JOIN client c ON c.client_uuid = const.client_uuid
                         INNER JOIN lease l ON l.lease_uuid = cs.lease_uuid
                         INNER JOIN client_currency cc_local
                                    ON cc_local.client_uuid = const.client_uuid AND
                                       cs.currency_code = cc_local.currency_code
                         INNER JOIN client_currency cc_user ON l.client_uuid = cc_user.client_uuid AND
                                                               cc_user.currency_code = const.currency_code_user
                  WHERE cs.deleted_at IS NULL
                    AND DATE_PART('year', cd.period_date) = c.report_yr
                  GROUP BY cs.lease_uuid) AS sql_period_amt ON l.lease_uuid = sql_period_amt.lease_uuid
where c.client_uuid = const.client_uuid
  AND p.deleted_at IS NULL
  AND l.deleted_at IS NULL
  AND p.property_status = 'active'
  AND l.lease_status = 'active';

rentArea converted by lease
===========================

select
  p.property_uuid,
  p.property_name,
  l.lease_uuid,
  l.lease_name,
  ls.lease_space_uuid,
  ls.floor_suite_space,
  ls.rent_area as rent_area_local,
  l.unit_meas,
  (case when l.unit_meas = 'sqm' then ls.rent_area*10.76391042 else ls.rent_area end) rent_area_user,
  ((case when l.unit_meas = 'sqm' then ls.rent_area*10.76391042 else ls.rent_area end)*lt.lease_type_pos_neg) as rent_area_net
from property p
  inner join lease l on p.property_uuid = l.property_uuid
  inner join lease_type lt on l.lease_type_uuid = lt.lease_type_uuid
  inner join lease_space ls on l.lease_uuid = ls.lease_uuid
where p.property_status = 'active' and p.deleted_at is null and l.client_uuid = '378db740-7041-4eaf-97e2-cb4002025c05' and l.lease_status = 'active' and l.deleted_at is null and ls.deleted_at is null
order by p.property_name, l.lease_name, l.lease_uuid ,ls.lease_space_uuid;

Bars metrics - leaseTotalAmountBreakdown
========================================
select cd.* from cost_sched cs
inner join cost_data cd on cs.cost_sched_uuid = cd.cost_sched_uuid
where cs.lease_uuid = 'd63d3102-8898-4ea6-b4bc-9fcd104bc904'
and cs.cost_sched_uuid = '3b237a60-c8e9-4b00-9aba-7f1a176b18f1'
and DATE_PART('year', cd.period_date) = 2024 order by cd.period_date;

*/
import { LeaseEntity } from 'entities/Lease/Lease';
import { calculateTotalRentAreaByLease } from 'utils/leaseSpaceHelper';
import { convertCurrency } from 'utils/unitConverters';

import { GraphData, GraphDataItem } from '../../../../../../types/dashboard-metric';
import { Preferences } from '../../../types/Preferences';
import { calculateLeaseSummary, ExpirationCounts } from './calculateLeaseSummary';
import { isPropertyAndLeaseFiltersPassing } from './filters';

export const calculateDashboardMetricsForLease = (
  preferences: Preferences,
  leases: LeaseEntity[],
  leaseExpirationCountsData: ExpirationCounts | undefined,
  metric: string
): GraphData => {
  const filteredLeases = leases.filter(isPropertyAndLeaseFiltersPassing);
  const { totalAmount, totalRentArea } = filteredLeases.reduce(
    (aggregations, lease) => {
      aggregations.totalAmount += convertCurrency(
        preferences,
        lease.costSummary?.annualCostWithoutTax ?? 0,
        lease.currencyCode
      );
      aggregations.totalRentArea += calculateTotalRentAreaByLease(lease, preferences.uom);
      return aggregations;
    },
    {
      totalAmount: 0,
      totalRentArea: 0,
    }
  );
  const amountPerArea = totalRentArea > 0 ? totalAmount / totalRentArea : 0;

  const summaryObject = calculateLeaseSummary(leases, metric, preferences);
  const leaseSummary = summaryObject.summary;

  const metrics: {
    [key: string]: GraphDataItem;
  } = {};

  for (const category in leaseSummary) {
    const leaseSummaryCategory = leaseSummary[category];
    const totalCostTaxed = leaseSummaryCategory.totalCostTaxed;
    const costPerAreaTaxed =
      leaseSummaryCategory.totalRentArea > 0
        ? totalCostTaxed / leaseSummaryCategory.totalRentArea
        : 0;
    metrics[category] = {
      id: category,
      rentArea: leaseSummaryCategory.totalRentArea,
      totalCostTaxed,
      costPerAreaTaxed,
    };
  }

  return {
    numberOfLeases: filteredLeases.length,
    rentableArea: totalRentArea,
    totalCostTaxed: totalAmount,
    costPerAreaTaxed: amountPerArea,
    expired: leaseExpirationCountsData?.expired ?? 0,
    metrics,
  };
};
