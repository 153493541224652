import { GridStyled } from 'components/pages/Home/components/HomeDashboard/HomeDashboard.css';
import HomeDashboardGraph from 'components/pages/Home/components/HomeDashboardGraph/HomeDashboardGraph';
import HomeDashboardList from 'components/pages/Home/components/HomeDashboardList/HomeDashboardList';
import { switchLanguage } from 'components/routing/utils/languageUtils';
import { PageWrapper } from 'components/ui/page-wrapper';
import { StackDirectionEnum } from 'enums/StackDirectionEnum';
import { useUserPreferences } from 'hooks/useUserPreferences';
import { NextPage } from 'next';
import React, { useEffect, useMemo } from 'react';
import { useRecoilState } from 'recoil';
import { userModelState } from 'recoil/atoms/userAtoms';
import colors from 'styles/colors';

import { useGetCachedLeases } from '../../../Leases/LeasesListPage/useGetCachedLeases';
import {
  ExpirationCounts,
  getLeaseExpirationCounts,
} from '../HomeDashboardGraph/helpers/calculateLeaseSummary';

const HomeDashboardPage: NextPage = () => {
  const { leasesData, loadingFinished: leaseDataLoadingFinished } = useGetCachedLeases(); // Service: leases/find-by-active-client

  const leaseExpirationCountsData: ExpirationCounts | undefined = useMemo(
    () => getLeaseExpirationCounts(leasesData, false),
    [leasesData]
  );

  const preferences = useUserPreferences();

  // Fail-safe to get the user-info data in case data was flushed.
  const [user] = useRecoilState(userModelState);

  useEffect(() => {
    switchLanguage(user?.selectedUserClient?.userLanguage);
  }, [user?.selectedUserClient?.userLanguage]);

  return (
    <PageWrapper padding="0" backgroundColor={colors.white}>
      <GridStyled
        container
        spacing={0}
        direction={StackDirectionEnum.ROW}
        alignContent="flex-start"
        alignItems="stretch"
      >
        <GridStyled
          item
          sm={4}
          md={3}
          container
          direction={StackDirectionEnum.COLUMN}
          alignContent="flex-start"
          alignItems="stretch"
        >
          {/* LEFT PANEL */}
          <HomeDashboardList
            leaseData={leasesData}
            loadingFinished={leaseDataLoadingFinished}
            preferences={preferences}
          />
        </GridStyled>
        <GridStyled
          item
          sm={8}
          md={9}
          container
          direction={StackDirectionEnum.COLUMN}
          alignContent="flex-start"
          alignItems="stretch"
        >
          {/* SUB-HEADER, MIDDLE & RIGHT PANEL */}
          <HomeDashboardGraph
            leases={leasesData}
            leaseExpirationCountsData={leaseExpirationCountsData}
            loadingFinished={leaseDataLoadingFinished}
            preferences={preferences}
          />
        </GridStyled>
      </GridStyled>
    </PageWrapper>
  );
};

export default HomeDashboardPage;
