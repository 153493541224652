import { Autocomplete, TextField, AutocompleteProps, TextFieldProps, Box } from '@mui/material';
import { useTranslation } from 'next-i18next';
import { FieldError } from 'react-hook-form';

type AutocompletePropsByComponent = AutocompleteProps<string, undefined, undefined, undefined>;
type AutocompleteItemType = GenericListItem;

interface AutocompleteByUuidProps {
  value: AutocompletePropsByComponent['value'];
  onChange: (...event: any[]) => void;
  label: TextFieldProps['label'];
  items: AutocompleteItemType[];
  error?: FieldError;
  inputRef?: TextFieldProps['inputRef'];
  required?: TextFieldProps['required'];
  disabled?: AutocompletePropsByComponent['disabled'];
  name?: TextFieldProps['name'];
  id?: TextFieldProps['id'];
  autoComplete?: string;
  filterSelectedOptions?: AutocompletePropsByComponent['filterSelectedOptions'];
  skipTranslations?: boolean;
}

export const AutocompleteByUuid = ({
  value,
  onChange,
  label,
  items = [],
  error,
  inputRef,
  required,
  disabled,
  name,
  id,
  autoComplete,
  filterSelectedOptions,
  skipTranslations = false,
}: AutocompleteByUuidProps) => {
  const { t } = useTranslation();

  return (
    <Autocomplete
      autoHighlight
      size="small"
      options={items.map((item) => item.uuid)}
      getOptionLabel={(option) => {
        const translationKey = items.find((item) => item.uuid === option)?.translationKey;
        if (!translationKey?.length) return '';
        return skipTranslations ? translationKey : t(translationKey);
      }}
      value={value || ''}
      onChange={(_, newValue) => onChange(newValue)}
      disabled={disabled}
      filterSelectedOptions={filterSelectedOptions}
      autoComplete={false}
      renderInput={({ InputProps, inputProps, InputLabelProps, ...inputParams }) => (
        <TextField
          type="text"
          required={required}
          error={!!error}
          helperText={error?.message}
          label={label}
          name={name}
          autoComplete={autoComplete}
          inputProps={{
            ...inputProps,
            id: id,
            autoComplete: autoComplete,
          }}
          spellCheck="false"
          InputProps={{
            ...InputProps,
            inputRef: inputRef,
          }}
          InputLabelProps={{
            ...InputLabelProps,
            htmlFor: name,
          }}
          {...inputParams}
        />
      )}
      renderOption={(props, option) => {
        const item = items.find((opt) => opt.uuid === option);
        if (item?.deletedAt) return null;
        let translatedValue = '';
        if (item?.translationKey?.length) {
          translatedValue = skipTranslations ? item?.translationKey : t(item?.translationKey);
        }
        return (
          <Box component="li" {...props} value={option}>
            {translatedValue}
          </Box>
        );
      }}
    />
  );
};

type AutocompleteByNameItemType = GenericListItem;

interface AutocompleteByNameProps {
  value: AutocompletePropsByComponent['value'];
  onChange: (...event: any[]) => void;
  label: TextFieldProps['label'];
  items: AutocompleteByNameItemType[];
  error?: FieldError;
  inputRef?: TextFieldProps['inputRef'];
  required?: TextFieldProps['required'];
  disabled?: AutocompletePropsByComponent['disabled'];
  name?: TextFieldProps['name'];
  id?: AutocompletePropsByComponent['id'];
  autoComplete?: string;
}

export const AutocompleteByName = ({
  value,
  onChange,
  label,
  items = [],
  error,
  inputRef,
  required,
  disabled,
  name,
  id,
  autoComplete,
}: AutocompleteByNameProps) => {
  const { t } = useTranslation();
  return (
    <Autocomplete
      autoHighlight
      size="small"
      options={items.map((item) => item.translationKey)}
      getOptionLabel={(option) => t(option)}
      value={value || ''}
      onChange={(_, newValue) => onChange(newValue)}
      disabled={disabled}
      autoComplete={false}
      renderInput={({ InputProps, inputProps, InputLabelProps, ...inputParams }) => (
        <TextField
          required={required}
          error={!!error}
          helperText={error?.message}
          label={label}
          name={name}
          autoComplete={autoComplete}
          inputProps={{
            ...inputProps,
            id: id,
            autoComplete: autoComplete,
          }}
          InputProps={{
            ...InputProps,
            inputRef: inputRef,
          }}
          InputLabelProps={{
            ...InputLabelProps,
            htmlFor: name,
          }}
          {...inputParams}
        />
      )}
      renderOption={(props, option) => {
        const item = items.find((opt) => opt.uuid === option);
        if (item?.deletedAt) return null;
        return (
          <Box component="li" {...props} value={option}>
            {item?.translationKey?.length ? t(item?.translationKey) : ''}
          </Box>
        );
      }}
    />
  );
};
