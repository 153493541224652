import { TextFieldProps } from '@mui/material/TextField/TextField';
import { FormikValues } from 'formik';
import { useTranslation } from 'next-i18next';
import React from 'react';

import { StyledInput } from './Input.css';

interface InputProps<T> {
  label: string;
  name: keyof T;
  formik: FormikValues;
}

export const Input = <T,>({ label, name, formik, ...rest }: InputProps<T> & TextFieldProps) => {
  const { t } = useTranslation();

  const value = formik.values[name] || formik.values[name] === 0 ? formik.values[name] : '';

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    formik.setFieldTouched(name, true, false);
  };

  return (
    <StyledInput
      $readonly={rest.InputProps?.readOnly}
      {...rest}
      id={name as string}
      name={name as string}
      label={t(label)}
      fullWidth
      size="small"
      value={value}
      onChange={handleInputChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={formik.touched[name] && formik.errors[name]}
      {...(formik.values[name] && { shrink: true })}
    />
  );
};
