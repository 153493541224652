import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from 'styles/colors';

export const PageWrapper = styled(Box)<{
  tablePadding?: string;
  padding?: string;
  backgroundColor?: string;
}>(({ padding = '3.2rem', backgroundColor, tablePadding }) => ({
  padding: padding,
  height: '100%',
  width: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: backgroundColor ?? colors.mainBackground,
  '& .MuiTableContainer-root': {
    padding: tablePadding ?? 'initial',
  },
}));

export const CurrencyExchangePageWrapper = styled(Box)<{ padding?: string }>(
  ({ padding = '3.2rem' }) => ({
    padding: padding,
    height: '100%',
    width: '100%',
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  })
);
