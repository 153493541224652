import { Grid } from '@mui/material';
import { HomeDashboardGraphFilterContainer } from 'components/pages/Home/components/HomeDashboardGraph/HomeDashboardGraph.css';
import { Preferences } from 'components/pages/Home/types/Preferences';
import { ToggleButtons } from 'components/shared/ToggleButton';
import { SelectByUuid } from 'components/ui/form-components';
import { TBasePaths, TShortcuts } from 'constants/translations';
import { StackDirectionEnum } from 'enums/StackDirectionEnum';
import { useTranslation } from 'next-i18next';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

export enum DasboardMetricsEnum {
  REGION = 'property.region.regionName',
  SUBREGION = 'property.region.subregionName',
  COUNTRY = 'property.country.countryName',
  MARKET_METRO = 'property.marketMetro.marketMetroName',
  PROPERTY_NAME = 'property.propertyName',
  PROPERTY_TYPE = 'property.propertyType.propertyTypeName',
  BUSINESS = 'leaseBusiness.leaseBusinessName',
}

export const DASHBOARD_METRICS_DATA: GenericListItem[] = [
  {
    uuid: DasboardMetricsEnum.REGION,
    translationKey: `${TBasePaths.PA_COMMON_WORD}.region`,
  },
  {
    uuid: DasboardMetricsEnum.SUBREGION,
    translationKey: `${TBasePaths.PA_COMMON_MESSAGES}.subregion`,
  },
  {
    uuid: DasboardMetricsEnum.COUNTRY,
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.country`,
  },
  {
    uuid: DasboardMetricsEnum.MARKET_METRO,
    translationKey: `${TBasePaths.PA_COMMON_WORD}.marketMetro`,
  },
  {
    uuid: DasboardMetricsEnum.PROPERTY_NAME,
    translationKey: `${TBasePaths.PA_COMMON_WORD}.propertyName`,
  },
  {
    uuid: DasboardMetricsEnum.PROPERTY_TYPE,
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.propertyType`,
  },
  {
    uuid: DasboardMetricsEnum.BUSINESS,
    translationKey: `${TBasePaths.AVANT_COMMON_WORD}.business`,
  },
];

export const DASHBOARD_METRICS: string[] = DASHBOARD_METRICS_DATA.map((i) => i.uuid);

export const DASHBOARD_GRAPH_TOGGLES_DATA: GenericListItemWithPreferences[] = [
  { uuid: 'totalCost', keyFn: () => `${TBasePaths.AVANT_COMMON_WORD}.cost` },
  {
    uuid: 'rentArea',
    keyFn: (preferences) => `${TBasePaths.PA_ENUM}.unitMeas.${preferences.uom}`,
  },
  {
    uuid: 'costPerArea',
    keyFn: (preferences) => `${TBasePaths.PA_ENUM}.unitMeas.${preferences.uom}`,
  },
];

export const DASHBOARD_GRAPH_TOGGLES: string[] = DASHBOARD_GRAPH_TOGGLES_DATA.map((i) => i.uuid);

type GenericListItemWithPreferences = Omit<GenericListItem, 'translationKey'> & {
  keyFn: (preferences: Preferences) => string;
};

const getTypeValueButtons = (t: any, preferences: Preferences) => {
  return DASHBOARD_GRAPH_TOGGLES_DATA.map((item) => {
    let name = t(item.keyFn(preferences));
    if (item.uuid === 'costPerArea') {
      name = `${preferences.currencySymbol} / ${name}`;
    }
    const value = item.uuid;
    return {
      name,
      value,
    };
  });
};

interface HomeDashboardGraphFilterProps {
  metric: string;
  typeValue: string;
  onMetricChange: (value: string) => void;
  onTypeValueChange: (value: string) => void;
  preferences: Preferences;
}

const HomeDashboardGraphFilterDropDown = ({
  metric = DASHBOARD_METRICS[0],
  typeValue = DASHBOARD_GRAPH_TOGGLES[0],
  onMetricChange,
  onTypeValueChange,
  preferences,
}: HomeDashboardGraphFilterProps) => {
  const { t } = useTranslation();

  const [initLoad, setInitLoad] = useState(true);

  const { control, getValues } = useForm({
    defaultValues: {
      metric: metric,
    },
  });

  const handleOnMetricChange = () => {
    setInitLoad(false);
    onMetricChange(getValues('metric'));
  };

  return (
    <HomeDashboardGraphFilterContainer container direction={StackDirectionEnum.ROW}>
      <Grid item sm={5}>
        <form>
          {/* @ts-ignore */}
          <Controller
            name={'metric'}
            control={control}
            rules={{
              required: t(TShortcuts.THIS_IS_REQUIRED),
            }}
            render={({ field: { onChange, value, ref }, fieldState: { error } }) => {
              // Set the value init dropdown to the UUID 'Market Metro'
              const selectedValue = initLoad ? DasboardMetricsEnum.MARKET_METRO : value;
              return (
                <SelectByUuid
                  error={error}
                  inputRef={ref}
                  onChange={(e) => {
                    onChange(e);
                    handleOnMetricChange();
                  }}
                  value={selectedValue}
                  label={''}
                  items={DASHBOARD_METRICS_DATA}
                  required={undefined}
                  data-testid="autocomplete-metric-input"
                />
              );
            }}
          />
        </form>
      </Grid>
      <Grid item>
        <ToggleButtons
          defaultValue={typeValue}
          // @ts-ignore
          buttonList={getTypeValueButtons(t, preferences)}
          onChange={(_, status) => onTypeValueChange(status)}
        />
      </Grid>
    </HomeDashboardGraphFilterContainer>
  );
};

export default HomeDashboardGraphFilterDropDown;
