import { DASHBOARD_GRAPH_TOGGLES } from 'components/pages/Home/components/HomeDashboardGraph/HomeDashboardGraphFilterDropDown';
import MUITable from 'components/ui/table-chart/MUITable';
import colors from 'styles/colors';
import { FormatterType } from 'utils/formatters';

import { GraphData, GraphDataItem } from '../../../../../types/dashboard-metric';

const getValueByType = (item: GraphDataItem, key: string) => {
  const responses = [item.totalCostTaxed, item.rentArea, item.costPerAreaTaxed];
  const index = DASHBOARD_GRAPH_TOGGLES.indexOf(key);
  return responses[Math.max(0, index)] ?? 0;
};

const getFormatByType = (key: string) => {
  const responses = [FormatterType.INTEGER, FormatterType.INTEGER, FormatterType.FLOAT_TWO_DECIMAL];
  const index = DASHBOARD_GRAPH_TOGGLES.indexOf(key);
  return responses[Math.max(0, index)];
};

interface LeasesMetricsProps {
  typeValue: string;
  data: GraphData | undefined;
}

const LeasesMetrics = ({ typeValue, data }: LeasesMetricsProps) => {
  const metricData: { label: string; value: number }[] = [];
  if (data?.metrics) {
    Object.values(data.metrics).forEach((item) => {
      if (item.id) {
        const value = getValueByType(item, typeValue);
        metricData.push({ label: item.id, value });
      }
    });
  }

  metricData.sort((a, b) => b.value - a.value);
  const metricLabels = metricData.map((item) => item.label);
  const metricValues = metricData.map((item) => ({
    COUNT: item.value,
  }));

  return (
    <MUITable
      data={metricValues}
      firstColumnLabels={metricLabels}
      barFillColor={colors.amethystMain}
      labelsFormatter={[getFormatByType(typeValue)]}
      suffix=""
    />
  );
};

export default LeasesMetrics;
