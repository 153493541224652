import { CostDataEntity } from 'entities/CostData';

import { LeaseEntity } from '../../../../../../entities/Lease/Lease';

export const isActive = (deletedAt: string | undefined) => {
  return String(deletedAt) === 'null';
};
export const isPropertyAndLeaseFiltersPassing = (lease: LeaseEntity) => {
  return (
    isActive(lease.property?.deletedAt) &&
    lease.property?.propertyStatus === 'active' &&
    isActive(lease.deletedAt) &&
    lease.leaseStatus === 'active'
  );
};
export const isCostDataInYear = (costData: CostDataEntity, year = new Date().getFullYear()) => {
  const costDataYear = costData.periodDate ? new Date(costData.periodDate).getUTCFullYear() : year;

  return year === costDataYear;
};
