import { memo } from 'react';
import * as d3 from 'd3';
import colors, { LRMCustomDotsColors } from 'styles/colors';
import { formatAmountBasedOnLocale } from 'utils/unitConverters';
import { useUserPreferences } from 'hooks/useUserPreferences';
import { FormatterType } from 'utils/formatters';
import { withContentRect } from 'react-measure';
import { guesstimateNumberLabelWidthBasedOnConst } from 'utils/d3Helper';

interface BarColumnCellProps {
  value: number;
  category: string;
  data: { COUNT: number }[];
  isLastRow: boolean;
  barFillColor?: string;
  labelsFormatter: string;
  suffix?: string | null;
}

function BarColumnCell({
  value,
  category,
  data,
  isLastRow,
  barFillColor,
  labelsFormatter,
  suffix = '',
}: BarColumnCellProps) {
  const barValues = value !== 0 ? value : '';
  const hasValue = value !== 0;
  const hasSuffix = suffix && String(suffix).length > 0;

  const getMaxValueBasedOnObjectProperty = (array: { [name: string]: any }[], objectName: string) =>
    Math.max(...array.map((object) => parseFloat(object[objectName]), 10));

  const maxDataVal = getMaxValueBasedOnObjectProperty(data, 'COUNT');
  const minDataVal = -maxDataVal;

  const color = barFillColor ? barFillColor : LRMCustomDotsColors[category];

  const decimals = labelsFormatter === FormatterType.FLOAT_TWO_DECIMAL ? 2 : 0;
  const preferences = useUserPreferences();
  const textFormatted = formatAmountBasedOnLocale(
    barValues ?? 0,
    decimals,
    preferences.formatNumber
  );
  const textValue = hasValue && hasSuffix ? `${textFormatted} ${String(suffix)}` : textFormatted;

  const BarComponentMeasured = withContentRect('bounds')(({ measureRef, contentRect }) => {
    const {
      // @ts-ignore
      bounds: { width },
    } = contentRect;
    const totalBarWidth = width * 2;

    const xScale = d3.scaleLinear().domain([minDataVal, maxDataVal]).range([0, totalBarWidth]);

    const scale = xScale(value) - xScale(0);
    const rectX = 500;

    const barWidth = Math.abs(scale);
    const textSizeGuesstimate = guesstimateNumberLabelWidthBasedOnConst(textValue, 9);
    const isTextLabelTooBigWithBar = textSizeGuesstimate + barWidth + 10 > width ? true : false;
    const isTextAndBarBiggerThanWidth =
      isTextLabelTooBigWithBar && textSizeGuesstimate > barWidth ? true : false;

    const textX = isTextLabelTooBigWithBar
      ? rectX + barWidth - textSizeGuesstimate + textSizeGuesstimate / 3 - 5
      : rectX + Math.abs(scale) + 5;
    const textColor = isTextLabelTooBigWithBar ? colors.white : colors.black;

    return (
      <div id="bar-div">
        <svg id="bar-wrapper" ref={measureRef} height={20} width="100%">
          <g id="bar-bounds" style={{ transform: `translate(-50rem, 0rem)` }}>
            <g id="chart-group" fill={color}>
              <rect x={rectX} y="0" width={barWidth} height="20" rx="4" ry="4" />
              <text x={textX} y="14" fill={textColor} fontSize="1rem">
                {isTextAndBarBiggerThanWidth ? '' : textValue}
              </text>
            </g>
          </g>
        </svg>
      </div>
    );
  });

  return <>{!isLastRow && <BarComponentMeasured />}</>;
}

export default memo(BarColumnCell);
