import { RecoilStateEnum } from 'enums/recoilState.enum';
import { useEffect } from 'react';
import { useRecoilRefresher_UNSTABLE, useRecoilValue, useRecoilValueLoadable } from 'recoil';
import { leasesState } from 'recoil/atoms/leasesAtom';
import { userModelState } from 'recoil/atoms/userAtoms';
import { getLeasesSelector } from 'recoil/selectors/leaseSelectors';

export const useGetCachedLeases = () => {
  const user = useRecoilValue(userModelState);
  const activeClientUuid = user.selectedUserClient?.clientUuid ?? '';
  const selector = getLeasesSelector({ clientUuid: activeClientUuid });
  const loadable = useRecoilValueLoadable(selector);
  const refresh = useRecoilRefresher_UNSTABLE(selector);

  const cachedData = useRecoilValue(leasesState(activeClientUuid));
  const loadingFinished =
    loadable.state === RecoilStateEnum.HAS_VALUE || (cachedData && cachedData.length > 0);

  useEffect(() => refresh(), [refresh]);

  return {
    leasesData: cachedData,
    loadingFinished,
    refresh,
  };
};
