import { Box, CircularProgress } from '@mui/material';
import { SystemProps, Theme } from '@mui/system';
import { ReactNode } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import DoubleArrowDown from './DoubleArrowDown';

export const AyInfiniteScroll = ({
  children,
  dataLength,
  hasMore,
  next,
  parentId = 'scrollableDiv',
  height = '100%',
  loader = (
    <Box sx={{ textAlign: 'center', padding: '2.4rem' }}>
      <CircularProgress />
    </Box>
  ),
  endMessage,
  ...otherStyleProps
}: InfiniteScrollProps) => {
  return (
    <Box
      id={parentId}
      sx={{
        padding: '2rem 3.2rem 3.2rem 3.2rem',
        height: height,
        overflow: 'auto',
        ...otherStyleProps,
      }}
    >
      <InfiniteScroll
        dataLength={dataLength}
        next={next}
        hasMore={hasMore}
        loader={loader}
        scrollableTarget={parentId}
        endMessage={endMessage}
      >
        {children}
      </InfiniteScroll>
      {hasMore && (
        <Box
          sx={{
            justifyContent: 'center',
            display: 'flex',
            paddingBottom: '2rem',
          }}
        >
          <DoubleArrowDown />
        </Box>
      )}
    </Box>
  );
};

export interface InfiniteScrollProps extends SystemProps<Theme> {
  children?: ReactNode;
  dataLength: number;
  hasMore: boolean;
  next: () => void;
  parentId?: string;
  height?: string;
  loader?: ReactNode;
  endMessage: ReactNode;
}

export default AyInfiniteScroll;
