import { CountryEntity } from 'entities/Country';
import { CurrencyEntity } from 'entities/Currency';

export type LeaseSecDepTypeEnumType = {
  cash_deposit: { key: string };
  line_credit: { key: string };
  guarantor: { key: string };
  none: { key: string };
};

export type ClauseStatusEnumType = {
  exercised: { key: string };
  active: { key: string };
  declined: { key: string };
  cancelled: { key: string };
};

export type FileStatusEnumType = {
  final: { key: string };
  incomplete: { key: string };
  missing: { key: string };
  superseded: { key: string };
  not_specified: { key: string };
};

export type TxRagStatusEnumType = {
  grey: { key: string };
  green: { key: string };
  amber: { key: string };
  red: { key: string };
};

export type TxPriorityEnumType = {
  none: { key: string };
  high: { key: string };
  med: { key: string };
  low: { key: string };
};
export type TxStatusEnumType = {
  new: { key: string };
  active: { key: string };
  completed: { key: string };
  hold: { key: string };
  cancelled: { key: string };
};

export type PartialPeriodMethodEnumType = {
  none: { key: string };
  daily: { key: string };
  period: { key: string };
};
export type ClauseDateTypeEnumType = {
  none: { key: string };
  fixed: { key: string };
  rolling_notice: { key: string };
  rolling_effective: { key: string };
};

export type ActivityLogEventTypeEnumType = {
  create_lease: { key: string };
  update_lease: { key: string };
  delete_lease: { key: string };
  create_property: { key: string };
  update_property: { key: string };
  delete_property: { key: string };
  create_currency_rate: { key: string };
  update_currency_rate: { key: string };
  delete_currency_rate: { key: string };
  create_clause: { key: string };
  update_clause: { key: string };
  delete_clause: { key: string };
  create_cost_schedule: { key: string };
  update_cost_schedule: { key: string };
  delete_cost_schedule: { key: string };
  create_responsibility: { key: string };
  update_responsibility: { key: string };
  delete_responsibility: { key: string };
  create_l_contact: { key: string };
  update_l_contact: { key: string };
  delete_l_contact: { key: string };
  create_contact: { key: string };
  update_contact: { key: string };
  delete_contact: { key: string };
  create_doc_file: { key: string };
  update_doc_file: { key: string };
  delete_doc_file: { key: string };
};

export enum LeaseStatusEnum {
  active = 'active',
  inactive = 'inactive',
  committed = 'committed',
  proposed = 'proposed',
}
export type LeaseStatusEnumType = Record<LeaseStatusEnum, { key: string }>;

export enum LeaseSpaceStatusEnum {
  active = 'active',
  inactive = 'inactive',
}

export type LeaseSpaceStatusEnumType = Record<LeaseSpaceStatusEnum, { key: string }>;

export enum CostSchedStatusEnum {
  actual = 'actual',
  budget = 'budget',
  estimate = 'estimate',
}
export type CostSchedStatusEnumType = Record<CostSchedStatusEnum, { key: string }>;

export type PrismaEnums = {
  ActivityLogEventTypeEnum: ActivityLogEventTypeEnumType;
  ClauseStatusEnum: ClauseStatusEnumType;
  ClauseDateTypeEnum: ClauseDateTypeEnumType;
  UnitMeasEnum: {
    sqf: { key: string };
    sqm: { key: string };
  };
  AccountingTreatmentEnum: {
    not_applicable: { key: string };
    capitalization: { key: string };
    short_term_exemption: { key: string };
    low_value_exemption: { key: string };
  };
  CostSchedFrequencyEnum: {
    monthly: { key: string };
    annually: { key: string };
    semi_annually: { key: string };
    quarterly: { key: string };
    onetime: { key: string };
  };
  CostSchedAmountUnitTypeEnum: {
    total: { key: string };
    payment: { key: string };
    annually_per_unit_area: { key: string };
    monthly_per_unit_area: { key: string };
    amt_per_unit: { key: string };
  };
  CostSchedStatusEnum: CostSchedStatusEnumType;
  CostSchedReportingTypeEnum: {
    both: { key: string };
    p_l: { key: string };
    cash: { key: string };
  };
  CostSchedClassificationEnum: {
    rent: { key: string };
    additional_rent: { key: string };
    parking: { key: string };
    security_deposit: { key: string };
    incentive: { key: string };
  };
  CountryRegionEnum: {
    apac: { key: string };
    americas: { key: string };
    emea: { key: string };
    not_specified: { key: string };
  };
  LeaseStatusEnum: LeaseStatusEnumType;
  LeaseSpaceStatusEnum: LeaseSpaceStatusEnumType;
  LeaseAbstractStatusEnum: {
    draft: { key: string };
    review: { key: string };
    abstracted: { key: string };
  };
  LeaseRentTypeEnum: {
    not_Specified: { key: string };
    full_service_gross: { key: string };
    modified_gross: { key: string };
    triple_net: { key: string };
    industrial_gross: { key: string };
  };
  LeaseSecDepTypeEnum: LeaseSecDepTypeEnumType;
  LeaseSecDepStatusEnum: {
    paid: { key: string };
    returned: { key: string };
  };
  PaymentTypeEnum: {
    eft: { key: string };
    check: { key: string };
    pad: { key: string };
  };
  PropertyStatusEnum: {
    active: { key: string };
    inactive: { key: string };
    proposed: { key: string };
    future: { key: string };
  };
  PropertyOwnershipTypeEnum: {
    leased: { key: string };
    owned: { key: string };
    ground: { key: string };
    joint_venture: { key: string };
    not_specified: { key: string };
    mixed: { key: string };
    membership: { key: string };
  };
  ResponsibilityResponsiblePartyEnum: {
    silent: { key: string };
    tenant: { key: string };
    landlord: { key: string };
  };
  UserClientEnum: {
    admin: { key: string };
    lease_admin: { key: string };
    client_user: { key: string };
    client_settings_admin: { key: string };
    team_member: { key: string };
    transaction_manager: { key: string };
    portfolio_manager: { key: string };
    custom: { key: string };
  };
  Countries: CountryEntity[];
  Currencies: CurrencyEntity[];
  ActivityTypeEnum: {
    all: { key: string };
    lease: { key: string };
    property: { key: string };
    clause: { key: string };
    cost_schedule: { key: string };
    responsibility: { key: string };
    l_contact: { key: string };
    contact: { key: string };
    exchange_rate: { key: string };
    doc_file: { key: string };
  };
  DocumentCategoryEnum: {
    all: { key: string };
    document: { key: string };
    image: { key: string };
  };
  DocumentTypeEnum: {
    building_photo: { key: string };
    floor_plan: { key: string };
    lease_document: { key: string };
    misc_document: { key: string };
    property_photo: { key: string };
  };
  LeaseLtAct54Enum: {
    inside: { key: string };
    outside: { key: string };
    not_applicable: { key: string };
  };
  PlanActionCategoryEnum: {
    Maintain: { key: string };
    Expand: { key: string };
    Contract: { key: string };
    Exit: { key: string };
    Not_Specified: { key: string };
  };
  PropertyClassEnum: {
    Trophy: { key: string };
    Class_a: { key: string };
    Class_b: { key: string };
    Class_c: { key: string };
    Unknown: { key: string };
  };
  TxStatusEnum: TxStatusEnumType;
  TxRagStatusEnum: TxRagStatusEnumType;
  TxPriorityEnum: TxPriorityEnumType;
  MarketTypeEnum: {
    industrial: { key: string };
    office: { key: string };
  };
  TaxTypeEnum: {
    all: { key: string };
    parking: { key: string };
    none: { key: string };
    other: { key: string };
  };
  FileStatusEnum: FileStatusEnumType;
  PartialPeriodMethodEnum: PartialPeriodMethodEnumType;
  InvoiceStatusEnum: {
    not_started: { key: string };
    in_progress: { key: string };
    completed: { key: string };
    contested: { key: string };
    reopened: { key: string };
    with_landlord: { key: string };
  };
  InvoiceTypeEnum: {
    year_end: { key: string };
    rent_adjustment: { key: string };
    real_estate_taxes: { key: string };
    other: { key: string };
  };
};
