import { CardActions, CardContent, styled, Typography } from '@mui/material';
import colors from 'styles/colors';

export const CardContentStyled = styled(CardContent)({
  '&.MuiCardContent-root': {
    padding: 0,
  },
});

export const CardActionStyled = styled(CardActions)(() => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: '0.8rem 0 0 0',
}));

export const LeaseIdText = styled(Typography)(() => ({
  color: colors.midnightLight,
}));
LeaseIdText.defaultProps = {
  // @ts-ignore
  variant: 'styledBold',
};
export const LeaseNameText = styled(Typography)(() => ({
  color: colors.midnightLight,
  marginTop: '0.2rem',
}));
LeaseNameText.defaultProps = {
  // @ts-ignore
  variant: 'styledBold',
};

export const LeasePropertyLocationText = styled(Typography)(() => ({
  marginBottom: '1.2rem',
}));
LeasePropertyLocationText.defaultProps = {
  // @ts-ignore
  variant: 'styledBold',
};
