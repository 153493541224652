import { memo } from 'react';
import { Typography } from '@mui/material';
import { StyledTableCell } from './MUITable.css';

interface CategoriesColumnCellProps {
  index: number;
  firstColumnLabels: string[];
  cellverticalpadding?: string | number;
  textWrap?: 'normal' | 'pre' | 'nowrap' | 'pre-wrap' | 'pre-line' | 'break-spaces';
}

const CategoriesColumnCell = ({
  index,
  firstColumnLabels,
  cellverticalpadding,
  textWrap,
}: CategoriesColumnCellProps) => (
  // See: GlobalStyle.ts
  <StyledTableCell cellverticalpadding={cellverticalpadding} className="column-width-limit">
    <Typography
      // @ts-ignore
      variant="tableTotals"
      whiteSpace={textWrap}
    >
      <b>{firstColumnLabels[index]}</b>
    </Typography>
  </StyledTableCell>
);

export default memo(CategoriesColumnCell);
