import { API_PATH } from 'components/routing/constants/api';
import { PrismaEnums } from 'enums/PrismaEnums';
import { selector } from 'recoil';
import { enumsState } from 'recoil/atoms/enumsAtom';
import { setRecoil } from 'recoil-nexus';

import { request } from '../../../pages/api/fetchService';
import { HttpMethod } from '../../enums/httpMethodEnum';
import { getURLByEndpoint } from './SelectorsHelper';

export const enumsSelector = selector({
  key: 'EnumsSelector',
  get: ({ getCallback }) => {
    return getCallback(() => async (accessToken: string) => {
      const result = await request<PrismaEnums>(
        getURLByEndpoint(API_PATH.enum.findAll),
        HttpMethod.GET,
        undefined,
        accessToken
      );
      setRecoil(enumsState, result as PrismaEnums);
      return result;
    });
  },
});
