import MUITable from 'components/ui/table-chart/MUITable';
import { useTranslation } from 'next-i18next';
import colors from 'styles/colors';

import { TBasePaths } from 'constants/translations';
import { ExpirationCounts } from '../HomeDashboardGraph/helpers/calculateLeaseSummary';

interface LeasesExpirationsProps {
  data: ExpirationCounts | undefined;
}

const LeasesExpirations = ({ data }: LeasesExpirationsProps) => {
  const { t } = useTranslation();

  const expirationData = [
    { label: 'leaseWidgetExpired', color: colors.errorMain, value: data?.expired },
    {
      label: 'leaseWidgetExpires1To12Months',
      color: colors.ruddyPink,
      value: data?.oneYearExpiration,
    },
    {
      label: 'leaseWidgetExpires13To24Months',
      color: colors.orangePeel,
      value: data?.twoYearsExpiration,
    },
    {
      label: 'leaseWidgetExpires25To36Months',
      color: colors.saffronMango,
      value: data?.threeYearsExpiration,
    },
    {
      label: 'leaseWidgetExpires4To5Years',
      color: colors.babyBlue,
      value: data?.fourAndFiveYearsExpiration,
    },
    {
      label: 'leaseWidgetExpires6OrMoreYears',
      color: colors.azure,
      value: data?.overSixYearsExpiration,
    },
    { label: 'owned', color: colors.astronautBlue, value: data?.ownedLeases },
    { label: 'leaseWidgetNoExpiration', color: colors.cloudGrey, value: data?.noExpiration },
  ];
  const expirationLabels = expirationData.map(({ label }) =>
    t(`${TBasePaths.PA_COMMON_WORD}.${label}`)
  );
  const expirationCategoryKeys = expirationData.map(({ label }) => label);
  const expirationValues = expirationData.map(({ value }) => {
    return { COUNT: value ?? 0 };
  });

  return (
    <MUITable
      data={expirationValues}
      firstColumnCategoryKeys={expirationCategoryKeys}
      firstColumnLabels={expirationLabels}
      barFillColor={undefined}
      labelsFormatter={['numberWithCommas']}
      suffix={null}
      textWrapCategoriesColumnCell="nowrap"
    />
  );
};

export default LeasesExpirations;
