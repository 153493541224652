import { toZonedTime } from 'date-fns-tz';
import { LeaseEntity } from 'entities/Lease/Lease';
import { monthDiffByMonth } from 'utils/dateHelper';
import { calculateTotalRentAreaByLease } from 'utils/leaseSpaceHelper';
import { convertCurrency } from 'utils/unitConverters';

import logger from '../../../../../../utils/logger';
import { Preferences } from '../../../types/Preferences';
import { isPropertyAndLeaseFiltersPassing } from './filters';

type Summary = {
  [category: string]: {
    totalRentArea: number;
    totalCostTaxed: number;
  };
};

export const calculateLeaseSummary = (
  leases: LeaseEntity[],
  metric: string,
  preferences: Preferences
) => {
  let summary: Summary = {};

  function getNestedValue(keys: string[], obj: any) {
    for (const key of keys) {
      if (!obj?.hasOwnProperty(key)) {
        return undefined;
      }
      obj = obj[key];
    }
    return obj;
  }

  for (let lease of leases) {
    if (!isPropertyAndLeaseFiltersPassing(lease)) {
      continue;
    }

    // Extract based on lease.property.marketMetro.marketMetroName;
    const categoryName = getNestedValue(metric.split('.'), lease) || 'Not specified';

    if (!summary[categoryName]) {
      summary[categoryName] = {
        totalRentArea: 0,
        totalCostTaxed: 0,
      };
    }

    if (lease.leaseSpace.length) {
      const totalRentAreaByLease = calculateTotalRentAreaByLease(lease, preferences.uom);
      summary[categoryName].totalRentArea += totalRentAreaByLease;
    }

    summary[categoryName].totalCostTaxed += convertCurrency(
      preferences,
      lease.costSummary?.annualCostWithoutTax ?? 0,
      lease.currencyCode
    );
  }

  return {
    summary,
  };
};

export type ExpirationCounts = {
  expired: number;
  oneYearExpiration: number;
  twoYearsExpiration: number;
  threeYearsExpiration: number;
  fourAndFiveYearsExpiration: number;
  overSixYearsExpiration: number;
  noExpiration: number;
  ownedLeases: number;
};

export const getLeaseExpirationCounts = (leases: LeaseEntity[], isDebug: boolean) => {
  const currentDate = toZonedTime(new Date(), 'UTC');

  const expirationPeriods = [
    { startMonth: -Infinity, endMonth: 0, label: 'expired' },
    { startMonth: 0, endMonth: 12, label: 'oneYearExpiration' },
    { startMonth: 13, endMonth: 24, label: 'twoYearsExpiration' },
    { startMonth: 25, endMonth: 36, label: 'threeYearsExpiration' },
    { startMonth: 37, endMonth: 60, label: 'fourAndFiveYearsExpiration' },
    { startMonth: 61, endMonth: Infinity, label: 'overSixYearsExpiration' },
  ];

  const expirationCounts: ExpirationCounts = {
    expired: 0,
    oneYearExpiration: 0,
    twoYearsExpiration: 0,
    threeYearsExpiration: 0,
    fourAndFiveYearsExpiration: 0,
    overSixYearsExpiration: 0,
    noExpiration: 0,
    ownedLeases: 0,
  };

  let {
    expired,
    oneYearExpiration,
    twoYearsExpiration,
    threeYearsExpiration,
    fourAndFiveYearsExpiration,
    overSixYearsExpiration,
    noExpiration,
    ownedLeases,
  } = expirationCounts;

  leases.forEach((lease) => {
    if (!isPropertyAndLeaseFiltersPassing(lease)) return;
    if (lease.leaseType?.leaseTypeName === 'Owned') {
      ownedLeases += 1;
      return;
    }

    // If the lease has an expiration date & filters passing
    if (lease.expireDate) {
      // Get the expiration date and calculate the difference in months from the current date
      const expireDate = toZonedTime(lease.expireDate, 'UTC');
      const diffMonths = monthDiffByMonth(currentDate, expireDate);

      // Determine which expiration period the lease falls into and increment the count for that period
      switch (true) {
        case diffMonths >= expirationPeriods[0].startMonth &&
          diffMonths <= expirationPeriods[0].endMonth:
          expired += 1;
          break;
        case diffMonths >= expirationPeriods[1].startMonth &&
          diffMonths <= expirationPeriods[1].endMonth:
          oneYearExpiration += 1;
          break;
        case diffMonths >= expirationPeriods[2].startMonth &&
          diffMonths <= expirationPeriods[2].endMonth:
          twoYearsExpiration += 1;
          break;
        case diffMonths >= expirationPeriods[3].startMonth &&
          diffMonths <= expirationPeriods[3].endMonth:
          threeYearsExpiration += 1;
          break;
        case diffMonths >= expirationPeriods[4].startMonth &&
          diffMonths <= expirationPeriods[4].endMonth:
          fourAndFiveYearsExpiration += 1;
          break;
        default:
          overSixYearsExpiration += 1;
          break;
      }
    } else {
      noExpiration += 1;
    }
  });

  const retVal = {
    expired,
    oneYearExpiration,
    twoYearsExpiration,
    threeYearsExpiration,
    fourAndFiveYearsExpiration,
    overSixYearsExpiration,
    noExpiration,
    ownedLeases,
  };

  if (isDebug) {
    logger('===================== Bar chart : calculateLeaseSummary =======================');
    logger(JSON.stringify(retVal));
  }

  return retVal;
};
