import { Skeleton, Stack, styled } from '@mui/material';

export const ListSkeletonLoader = styled(Stack)(() => ({
  width: '100%',
  height: '100%',
  padding: '2.4rem 3.2rem',
}));

export const CardSkeletonStack = styled(Stack)(() => ({
  width: '100%',
}));

export const CardSkeletonTop = styled(Skeleton)(() => ({
  width: '100%',
  height: '12rem !important',
}));
CardSkeletonTop.defaultProps = {
  variant: 'rectangular',
};

export const CardSkeletonBottom = styled(Skeleton)(() => ({
  width: '100%',
  height: '4rem !important',
  marginTop: '0.1rem',
  marginBottom: '2.4rem',
}));
CardSkeletonBottom.defaultProps = {
  variant: 'rectangular',
};
