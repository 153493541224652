import { Box, Card, Grid, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import colors from 'styles/colors';

export const HomeLeasesCardContainer = styled(Grid)(() => ({
  height: '100%',
  flexWrap: 'nowrap',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
}));

export const HomeLeasesHeader = styled(Grid)(() => ({
  padding: '1rem',
  backgroundColor: colors.white,
  flexDirection: 'row',
  alignContent: 'center',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
}));

export const HomeLeasesCardList = styled(Grid)(() => ({
  flexGrow: '1',
  maxHeight: '100%',
  overflowX: 'unset',
  overflowY: 'auto',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'stretch',
}));

export const HomeLeasesCard = styled(Card)(() => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: '1rem',
  padding: '0.8rem 1.2rem',
  gap: '0.8rem',
  backgroundColor: colors.white,
  border: `0.1rem solid ${colors.borderColor}`,
  borderRadius: '0.4rem',
  boxShadow: `0em 0.2em 0.1em -0.1em ${colors.boxShadow}, 0em 0.1em 0.1em ${colors.boxShadow}, 0em 0.1em 0.3em ${colors.boxShadow}`,
  cursor: 'pointer',
}));

export const LeaseCardContent = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
}));

export const LeaseCardDivider = styled(Box)(() => ({
  flexGrow: '1',
  marginTop: '1.2rem',
  borderTop: `0.1rem solid ${colors.borderColor}`,
}));

export const DashboardLeaseList = styled(Paper)(() => ({
  display: 'flex',
  flexGrow: '1',
  height: '100%',
  width: '100%',
  backgroundColor: colors.backgroundColor,
}));

export const StyledBoxInInfiniteScroll = styled(Box)(() => ({
  textAlign: 'center',
  color: colors.midnightLight,
  padding: '2.4rem',
  '& .MuiTypography-root': {
    color: colors.black,
  },
}));
