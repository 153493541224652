export const doItemsContainSearch = (
  itemsToSearch: (string | number | null | undefined)[],
  searchTerm: string
) => {
  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  return itemsToSearch.some(
    (item) => item && item.toString().toLowerCase().includes(lowerCaseSearchTerm)
  );
};

export function notEmpty<T>(value: T | null | undefined): value is T {
  return (
    value !== null &&
    value !== undefined &&
    (typeof value !== 'string' || (typeof value === 'string' && value.length > 0))
  );
}
