import { Grid, Paper, styled, Typography } from '@mui/material';
import colors from 'styles/colors';
import { HOME_METRIC_HEADER, SITE_HEADER_HEIGHT } from 'utils/layoutHelper';

export const HomeDashboardGraphFilterContainer = styled(Grid)(() => ({
  paddingTop: '1.5rem',
  paddingBottom: '1.5rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  alignContent: 'center',
}));

export const LeaseExpirationText = styled(Typography)(() => ({
  color: colors.midnightLight,
  paddingBottom: '1.6rem',
}));
LeaseExpirationText.defaultProps = {
  // @ts-ignore
  variant: 'styledVacantBox',
};

export const MetricTitleText = styled(Typography)(() => ({
  color: colors.midnightLight,
}));
MetricTitleText.defaultProps = {
  // @ts-ignore
  variant: 'styledVacantBox',
};

export const RightPanel = styled(Grid)`
  border-top: 0.1rem solid ${colors.borderColor};
  border-left: 0.1rem solid ${colors.borderColor};
  padding: 1.5rem;
  position: relative;
  top: ${HOME_METRIC_HEADER};
`;

export const LeftPanel = styled(Grid)`
  border-top: 0.1rem solid ${colors.borderColor};
  top: ${HOME_METRIC_HEADER};
  position: relative;
  height: calc(100vh - ${HOME_METRIC_HEADER} - ${SITE_HEADER_HEIGHT} - 1.5rem);
  overflow: auto;
  display: flex;
  flex-direction: row;
  padding: 1.5rem;
`;

export const HomeDashboardGraphWrapper = styled(Paper)(() => ({
  display: 'flex',
  flexGrow: 1,
}));
