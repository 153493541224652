import { MenuItem, TextFieldProps } from '@mui/material';
import { TextFieldSelect } from 'components/ui/form-components/Select/Select.css';
import { FormikProps } from 'formik';
import { useTranslation } from 'next-i18next';
import React from 'react';

interface Option {
  uuid: string;
  name: string;
}
interface SelectFieldProps<T> extends Omit<TextFieldProps, 'select' | 'value' | 'onChange'> {
  label: string;
  name: Extract<keyof T, string>;
  formik: FormikProps<T>;
  options: Option[];
  settingKeyName?: keyof Option;
}

export const Select = <T,>({
  label,
  name,
  formik,
  options,
  settingKeyName = 'name',
  tabIndex,
  ...rest
}: SelectFieldProps<T>) => {
  const { t } = useTranslation();

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    formik.handleChange(event);
    formik.setFieldTouched(name, true, false);
  };

  return (
    <TextFieldSelect
      {...rest}
      select
      defaultValue=""
      size="small"
      fullWidth
      label={t(label)}
      name={name}
      value={formik.values[name] || ''}
      onChange={handleChange}
      error={formik.touched[name] && Boolean(formik.errors[name])}
      helperText={
        formik.touched[name] && formik.errors[name] ? <>{formik.errors[name]}</> : undefined
      }
      InputProps={{
        style: { minHeight: 'unset' }, // Override minimum height
      }}
      inputProps={{ tabIndex: tabIndex || undefined }}
    >
      {options?.map((option) => (
        <MenuItem key={option.uuid} value={option[settingKeyName as keyof Option]}>
          {option.name}
        </MenuItem>
      ))}
    </TextFieldSelect>
  );
};
