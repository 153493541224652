import type { ReactNode } from 'react';
import { memo } from 'react';
import dynamic from 'next/dynamic';
import { GlobalScrollbar } from 'mac-scrollbar';
import Script from 'next/script';
import 'mac-scrollbar/dist/mac-scrollbar.css';
import AppEnumsProvider from 'components/providers/AppEnumsProvider';

const MiniDrawer = dynamic(() => import('components/shared/drawer/Drawer'));

interface BaseLayoutProps {
  children: ReactNode;
}

function BaseLayoutWithDrawer({ children }: BaseLayoutProps) {
  return (
    <>
      <Script id="cloudinaryScript" src="https://widget.cloudinary.com/v2.0/global/all.js" />
      <AppEnumsProvider>
        <GlobalScrollbar />
        <MiniDrawer>{children}</MiniDrawer>
      </AppEnumsProvider>
    </>
  );
}

export default memo(BaseLayoutWithDrawer);
