import {
  Brightness1Outlined as NotSpecifiedIcon,
  CheckCircle as FinalIcon,
  DoNotDisturbAlt as SupersededIcon,
  RemoveCircle as IncompleteIcon,
  WarningAmber as MissingIcon,
} from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { useAppEnums } from 'components/providers/AppEnumsProvider';
import { ChipComponent, ChipComponentProps } from 'components/ui/chip/ChipComponent/ChipComponent';
import { TBasePaths } from 'constants/translations';
import { ChipColor } from 'enums/ChipColorEnum';
import { PrismaEnums } from 'enums/PrismaEnums';
import { useTranslation } from 'next-i18next';

function checkDocumentLabel(documentsStatusEnum: PrismaEnums['FileStatusEnum'], label: string) {
  const enumKeys: string[] = Object.values(documentsStatusEnum).map((item) => item?.key);

  const isKnownClauseLabel = Object.values<string>(enumKeys).includes(label);
  return isKnownClauseLabel;
}

export const DocumentChip = ({ label, chipSize, minWidth, maxWidth }: ChipComponentProps) => {
  const { t } = useTranslation();
  const { FileStatusEnum } = useAppEnums();

  const { final, not_specified: notSpecified, incomplete, missing, superseded } = FileStatusEnum;

  const documentChipIconMap = {
    [final?.key]: <FinalIcon fontSize="small" />,
    [notSpecified?.key]: <NotSpecifiedIcon fontSize="small" />,
    [incomplete?.key]: <IncompleteIcon fontSize="small" />,
    [missing?.key]: <MissingIcon fontSize="small" />,
    [superseded?.key]: <SupersededIcon fontSize="small" />,
  };

  const documentChipColorMap: Record<string, ChipColor> = {
    [final?.key]: ChipColor.Blue,
    [notSpecified?.key]: ChipColor.Default,
    [incomplete?.key]: ChipColor.Warning,
    [missing?.key]: ChipColor.Error,
    [superseded?.key]: ChipColor.Warning,
  };

  const isKnownDocumentLabel = checkDocumentLabel(FileStatusEnum, label);

  const documentIcon = isKnownDocumentLabel ? documentChipIconMap[label] : undefined;
  const documentColor = isKnownDocumentLabel ? documentChipColorMap[label] : ChipColor.Default;

  return (
    <Tooltip title={t(`${TBasePaths.PA_ENUM}.fileStatus.${label}`)}>
      <div>
        <ChipComponent
          label={t(`${TBasePaths.PA_ENUM}.fileStatus.${label}`)}
          icon={documentIcon}
          chipSize={chipSize}
          chipColor={documentColor}
          minWidth={minWidth}
          maxWidth={maxWidth}
        />
      </div>
    </Tooltip>
  );
};
