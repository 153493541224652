import { ToggleButtons } from 'components/shared/ToggleButton';
import { TBasePaths } from 'constants/translations';
import { useTranslation } from 'next-i18next';

export const LEASE_DASHBOARD_STATUSES: string[] = ['edited', 'draft'];

const getFilterButtons = (t: any) =>
  LEASE_DASHBOARD_STATUSES.map((item) => {
    return {
      name: t(`${TBasePaths.PA_COMMON_WORD}.${item.toLocaleLowerCase()}`),
      value: item,
    };
  });

interface HomeDashboardStatusFilterProps {
  selectedStatus?: string;
  onStatusChange: (status: string) => void;
}

const HomeDashboardListStatusFilter = ({
  selectedStatus = LEASE_DASHBOARD_STATUSES[1], // Draft
  onStatusChange,
}: HomeDashboardStatusFilterProps) => {
  const { t } = useTranslation();
  return (
    <ToggleButtons
      defaultValue={selectedStatus}
      // @ts-ignore
      buttonList={getFilterButtons(t)}
      onChange={(_, status) => onStatusChange(status)}
    />
  );
};

export default HomeDashboardListStatusFilter;
