import { TBasePaths } from 'constants/translations';
import { format, parseISO } from 'date-fns';
import { i18n } from 'next-i18next';
import { formatInTimeZone, getRemainingMonths } from 'utils/dateHelper';

import { Preferences } from '../components/pages/Home/types/Preferences';
import {
  formatAmountBasedOnLocale,
  formatDateBasedOnPreferences,
  formatISODate,
} from './unitConverters';

const isValidNumberFormatter = (number: string | number) => {
  return !isNaN(parseInt(String(number)));
};

const numberIsNotValid = '-';

export enum FormatterType {
  FORMAT_DATE_DAY_MONTH_YEAR = 'formatDateDayMonthYear',
  FORMAT_DATE_DAY_SHORT_MONTH_YEAR = 'formatDateDayShortMonthYear',
  SQF_NUMBER_WITH_COMMAS = 'sqfNumberWithCommas',
  SQM_NUMBER_WITH_COMMAS = 'sqmNumberWithCommas',
  SQF_NUMBER_AS_KILO = 'sqfNumberAsKilo',
  ADD_DOLLAR_SIGN = 'addDollarSign',
  ADD_DOLLAR_SIGN_WITH_TWO_DECIMAL = 'addDollarSignWithTwoDecimal',
  ADD_DOLLAR_SIGN_WITH_COMMAS = 'addDollarSignWithCommas',
  DIVIDE_BY_THREE_ADD_KILO_AND_ADD_DOLLAR_SIGN_WITH_COMMAS = 'divideByThreeAddKiloAndAddDollarSignWithCommas',
  FLOAT_WITH_COMMAS = 'floatWithCommas',
  FLOAT_DOLLAR_SIGN_WITH_COMMAS = 'floatDollarSignWithCommas',
  NUMBER_WITH_COMMAS = 'numberWithCommas',
  INTEGER = 'integer',
  INTEGER_WITH_COMMAS = 'integerWithCommas',
  ADD_PERCENTAGE = 'addPercentage',
  FLOAT_TWO_DECIMAL = 'floatTwoDecimal',
  FLOAT_FOUR_DECIMAL_WITH_PERCENTAGE = 'floatFourDecimalWithPercentage',
  FLOAT_TWO_DECIMAL_WITH_PERCENTAGE = 'floatTwoDecimalWithPercentage',
  FLOAT_ONE_DECIMAL_WITH_PERCENTAGE = 'floatOneDecimalWithPercentage',
  FLOAT_WITH_NO_DECIMAL = 'floatWithNoDecimal',
  CONVERT_FLOAT_TO_PERCENTAGE = 'convertFloatToPercentage',
  CONVERT_FLOAT_TO_PERCENTAGE_NUMBER = 'convertFloatToPercentageNumber',
  CONVERT_FLOAT_TO_PERCENTAGE_NUMBER_WITH_TWO_DECIMAL = 'convertFloatToPercentageNumberWithTwoDecimal',
  CONVERT_FLOAT_TO_NUMBER_WITH_COMMAS_WITH_NO_DECIMAL = 'convertFloatToNumberWithCommasWithNoDecimal',
  CONVERT_FLOAT_TO_NUMBER_WITH_COMMAS_WITH_ONE_DECIMAL = 'convertFloatToNumberWithCommasWithOneDecimal',
  GET_FORMATTED_DATE_AND_TIME_WITH_DASH = 'getFormattedDateAndTimeWithDash',
  GET_FORMATTED_DATE_WITH_DASH = 'getFormattedDateWithDash',
  GET_FORMATTED_DATE_AND_TIME_WITH_COMMA = 'getFormattedDateAndTimeWithComma',
  GET_FORMATTED_DATE_WITH_COMMA = 'getFormattedDateWithComma',
  FORMAT_DATE_MONTH_DAY_YEAR_SLASH = 'formatDateMonthDayYearSlash',
  ADD_CURRENCY_SYMBOL_WITH_COMMAS_AND_SCALE = 'addCurrencySymbolWithCommasAndScale',
  FORMAT_REMAINING_TIME_IN_YEARS_AND_MONTHS = 'formatRemainingTimeInYearsAndMonths',
  COMMAS_AND_SCALE = 'commasAndScale',
  SQF_NUMBER_WITH_UPPER_CASE = 'sqfNumberWithUpperCase',
  SQF_OR_SQM_NUMBER = 'sqfOrSqmNumber',
  NUMBER_WITH_COMMAS_RSF_OR_RSM = 'numberWithCommasRsfOrRsm',
  REPLACE_UNDER_SCORE_WITH_SPACES = 'replaceUnderScoreWithSpaces',
  TRIM_STRING_WITH_SPACES = 'trimStringWithSpaces',
  GET_FORMATTED_DATE_YEAR_MONTH_DAY = 'getFormattedYearMonthDay',
  CURRENCY_TO_NUMBER = 'currencyToNumber',
  MINIMUM_TWO_INTEGER_DIGITS_TO_STRING = 'minimumTwoIntegerDigitsToString',
  FORMAT_AMOUNT_BASED_ON_LOCALE = 'formatAmountBasedOnLocale',
  FORMAT_DATE_BASED_ON_PREFERENCES = 'formatDateBasedOnPreferences',
  FORMAT_TIME_WITH_AM_PM = 'formatTimeWithAMPM',
  ADD_CURRENCY_SYMBOL_SIGN = 'addCurrencySymbolSign',
  CURRENCY_SYMBOL_WITH_COMMAS = 'currencySymbolWithCommas',
  FORMAT_NUMBER_AS_KILO_MILLION = 'formatNumberAsKiloMillion',
  FLOAT_ONE_DECIMAL = 'floatOneDecimal',
}

const formatAndScaleNumber = (num: string, currencySymbol = '') => {
  const parsedNum = Number(num);
  if (isNaN(parsedNum)) {
    return 'Invalid number';
  }
  const scalingFactors = [
    { factor: 1e9, label: 'B' },
    { factor: 1e6, label: 'M' },
    { factor: 1e3, label: 'K' },
    { factor: 1, label: '' },
  ];
  const { factor: scalingFactor, label } =
    scalingFactors.find(({ factor }) => parsedNum >= factor) ||
    scalingFactors[scalingFactors.length - 1];
  const scaledNum = parsedNum / scalingFactor;
  const formattedNum = scaledNum >= 10 ? scaledNum.toFixed(0) : scaledNum.toFixed(1);
  return `${currencySymbol}${formattedNum}${label}`;
};

export const getFormatter = (
  functionName: string,
  preferences?: Preferences,
  leaseUnitMeas?: string
) => {
  let currencySymbol = '$';
  let retVal = (num: any) => num;
  switch (functionName) {
    case FormatterType.FORMAT_DATE_MONTH_DAY_YEAR_SLASH:
      retVal = (date: string) => formatISODate(date, 'MM/dd/yyyy');
      break;
    case FormatterType.COMMAS_AND_SCALE:
      retVal = (num: string) => formatAndScaleNumber(num);
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_WITH_COMMAS_AND_SCALE:
      retVal = (num: string) => formatAndScaleNumber(num, '$');
      break;
    case FormatterType.FORMAT_DATE_DAY_MONTH_YEAR:
      retVal = (date: string) => formatISODate(date, 'dd-MMMM-yyyy');
      break;
    case FormatterType.FORMAT_DATE_DAY_SHORT_MONTH_YEAR:
      retVal = (date: string) => formatISODate(date, 'dd-LLL-yyyy');
      break;
    case FormatterType.GET_FORMATTED_DATE_YEAR_MONTH_DAY:
      retVal = (date: string) => formatISODate(date, 'yyyy-MM-dd');
      break;
    case FormatterType.SQF_NUMBER_WITH_COMMAS:
      retVal = (num: string) => `${parseInt(num, 10).toLocaleString()} sq. ft.`;
      break;
    case FormatterType.SQF_NUMBER_AS_KILO:
      retVal = (num: number) =>
        `${Intl.NumberFormat('en', { notation: 'compact' }).format(num)} sq. ft.`;
      break;
    case FormatterType.ADD_DOLLAR_SIGN:
      retVal = (num: number) => `$${num}`;
      break;
    case FormatterType.ADD_DOLLAR_SIGN_WITH_TWO_DECIMAL:
      retVal = (num: string) => `$${Number(num as unknown as number).toFixed(2)}`;
      break;
    case FormatterType.ADD_DOLLAR_SIGN_WITH_COMMAS:
      retVal = (num: string) => {
        if (parseInt(num, 10) >= 0) {
          return `$${parseInt(num, 10).toLocaleString()}`;
        } else {
          return `-$${(parseInt(num, 10) * -1).toLocaleString()}`;
        }
      };
      break;
    case FormatterType.DIVIDE_BY_THREE_ADD_KILO_AND_ADD_DOLLAR_SIGN_WITH_COMMAS:
      retVal = (num: number) => {
        let divNum = num / 100;
        if (divNum >= 0) {
          return `$${parseInt(divNum.toString(), 10).toLocaleString()} K`;
        } else {
          return `-$${(parseInt(divNum.toString()) * -1).toLocaleString()} K`;
        }
      };
      break;
    case FormatterType.FLOAT_WITH_COMMAS:
      retVal = (num: string) => {
        if (parseInt(num, 10) >= 0) {
          return `${parseFloat(num).toFixed(2).toLocaleString()}`;
        } else {
          return `-${(parseFloat(num) * -1).toFixed(2).toLocaleString()}`;
        }
      };
      break;
    case FormatterType.FLOAT_DOLLAR_SIGN_WITH_COMMAS:
      retVal = (num: string) => {
        if (parseInt(num, 10) >= 0) {
          return `$${parseFloat(num).toFixed(2).toLocaleString()}`;
        } else {
          return `-$${(parseFloat(num) * -1).toFixed(2).toLocaleString()}`;
        }
      };
      break;
    case FormatterType.NUMBER_WITH_COMMAS:
      retVal = (num: string) => num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      break;
    case FormatterType.NUMBER_WITH_COMMAS_RSF_OR_RSM:
      retVal = (num: string) =>
        `${num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} ${
          leaseUnitMeas === 'sqm' ? 'rsm' : 'rsf'
        }`;
      break;
    case FormatterType.INTEGER:
      retVal = (num: string) => parseInt(num);
      break;
    case FormatterType.INTEGER_WITH_COMMAS:
      retVal = (num: string) =>
        parseInt(num)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      break;
    case FormatterType.ADD_PERCENTAGE:
      retVal = (value: string | number) => value + '%';
      break;
    case FormatterType.FLOAT_TWO_DECIMAL:
      retVal = (num: string) => (num as unknown as number).toFixed(2);
      break;
    case FormatterType.FLOAT_FOUR_DECIMAL_WITH_PERCENTAGE:
      retVal = (num: string) => (num as unknown as number).toFixed(4) + '%';
      break;
    case FormatterType.FLOAT_TWO_DECIMAL_WITH_PERCENTAGE:
      retVal = (num: string) => (num as unknown as number).toFixed(2) + '%';
      break;
    case FormatterType.FLOAT_ONE_DECIMAL_WITH_PERCENTAGE:
      retVal = (num: string) => parseFloat(num).toFixed(1) + '%';
      break;
    case FormatterType.FLOAT_WITH_NO_DECIMAL:
      retVal = (num: string) => Math.round(num as unknown as number);
      break;
    case FormatterType.CONVERT_FLOAT_TO_PERCENTAGE:
      retVal = (num: string | number) => (Number(num) * 100).toFixed(2) + '%';
      break;
    case FormatterType.CONVERT_FLOAT_TO_PERCENTAGE_NUMBER:
      retVal = (num: string) => (num ? (+num * 100).toFixed() : 0);
      break;
    case FormatterType.CONVERT_FLOAT_TO_PERCENTAGE_NUMBER_WITH_TWO_DECIMAL:
      retVal = (num: string) => (num ? (+num * 100).toFixed(2) : 0);
      break;
    case FormatterType.CONVERT_FLOAT_TO_NUMBER_WITH_COMMAS_WITH_NO_DECIMAL:
      retVal = (num: number) =>
        num
          .toFixed(0)
          .toString()
          .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      break;
    case FormatterType.CONVERT_FLOAT_TO_NUMBER_WITH_COMMAS_WITH_ONE_DECIMAL:
      retVal = (num: string) => (num ? (+num * 100).toFixed(1) : 0);
      break;
    case FormatterType.GET_FORMATTED_DATE_AND_TIME_WITH_DASH:
      retVal = (date: string) => {
        const formattedDate = formatInTimeZone(parseISO(date), 'dd-MMM-yyyy', 'UTC');
        const formattedTime = formatInTimeZone(parseISO(date), 'h:mm a', 'UTC');
        return `${formattedDate} - ${formattedTime}`;
      };
      break;
    case FormatterType.GET_FORMATTED_DATE_WITH_DASH:
      retVal = (date: string) => {
        let formattedDate;
        if (date) {
          formattedDate = formatInTimeZone(parseISO(date), 'dd-MMM-yyyy', 'UTC');
        } else {
          formattedDate = '';
        }
        return formattedDate;
      };
      break;
    case FormatterType.GET_FORMATTED_DATE_AND_TIME_WITH_COMMA:
      retVal = (date: string) => {
        const formattedDate = formatInTimeZone(parseISO(date), 'MMM dd, yyyy', 'UTC');
        const formattedTime = formatInTimeZone(parseISO(date), 'h:mm a', 'UTC');
        return `${formattedDate} - ${formattedTime}`;
      };
      break;
    case FormatterType.GET_FORMATTED_DATE_WITH_COMMA:
      retVal = (date: string) => {
        let formattedDate;
        if (date) {
          formattedDate = formatInTimeZone(parseISO(date), 'MMM dd, yyyy', 'UTC');
        } else {
          formattedDate = '';
        }
        return formattedDate;
      };
      break;
    case FormatterType.FORMAT_REMAINING_TIME_IN_YEARS_AND_MONTHS:
      retVal = (dateString: string) => {
        const shortMonthsLabel = i18n!.t(`${TBasePaths.AVANT_ABBREVIATIONS}.monthsShort`);
        const expired = i18n!.t(`${TBasePaths.PA_COMMON_WORD}.leaseWidgetExpired`);
        const remainingMonths = getRemainingMonths(dateString);
        return remainingMonths != undefined
          ? remainingMonths >= 0
            ? `${remainingMonths} ${shortMonthsLabel}`
            : expired
          : '-';
      };
      break;
    case FormatterType.SQF_OR_SQM_NUMBER:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return `$${parseInt(num, 10).toLocaleString()} /  ${leaseUnitMeas?.toUpperCase()}`;
      };
      break;
    case FormatterType.SQF_NUMBER_WITH_UPPER_CASE:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return `${parseInt(num, 10).toLocaleString()} SQF`;
      };
      break;
    case FormatterType.TRIM_STRING_WITH_SPACES:
      retVal = (val: any) => {
        if (typeof val === 'string') {
          return val.replace(/^\s+|\s+$|\s+(?=\s)/g, '');
        }
        return val;
      };
      break;
    case FormatterType.REPLACE_UNDER_SCORE_WITH_SPACES:
      retVal = (str: string) => {
        return str.replace(/_/g, ' ');
      };
      break;
    case FormatterType.CURRENCY_TO_NUMBER:
      retVal = (str: string) => {
        const currencyRegex = /[^\d.-]/g;
        const sanitizedStr = str.replace(currencyRegex, '');
        const sanitizedFloat = parseFloat(sanitizedStr);
        return isNaN(sanitizedFloat) ? null : sanitizedFloat;
      };
      break;
    case FormatterType.MINIMUM_TWO_INTEGER_DIGITS_TO_STRING:
      retVal = (num: number) => {
        return num.toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          maximumFractionDigits: 2,
          useGrouping: false,
          minimumFractionDigits: 2,
        });
      };
      break;
    case FormatterType.FORMAT_AMOUNT_BASED_ON_LOCALE:
      retVal = (num: number) => {
        return formatAmountBasedOnLocale(num ?? 0, 0, preferences?.formatNumber);
      };
      break;
    case FormatterType.FORMAT_DATE_BASED_ON_PREFERENCES:
      retVal = (date: string) => {
        return preferences
          ? formatDateBasedOnPreferences(preferences, date)
          : formatISODate(date, 'MM/dd/yyyy');
      };
      break;
    case FormatterType.FORMAT_TIME_WITH_AM_PM:
      retVal = (date: string) => {
        return format(new Date(date), 'h:mm aaa');
      };
      break;
    case FormatterType.ADD_CURRENCY_SYMBOL_SIGN:
      retVal = (num: number) => {
        if (!isValidNumberFormatter(num)) {
          return numberIsNotValid;
        }
        return `${currencySymbol}${Number(num).toFixed(2)}`;
      };
      break;
    case FormatterType.CURRENCY_SYMBOL_WITH_COMMAS:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        if (parseFloat(num) > 0) {
          return `${currencySymbol}${parseFloat(num).toFixed(2).toLocaleString()}`;
        } else {
          return `-${currencySymbol}${(parseFloat(num) * -1).toFixed(2).toLocaleString()}`;
        }
      };
      break;
    case FormatterType.FORMAT_NUMBER_AS_KILO_MILLION:
      retVal = (num: number): string =>
        Intl.NumberFormat('en', { notation: 'compact' }).format(num);
      break;
    case FormatterType.FLOAT_ONE_DECIMAL:
      retVal = (num: string) => {
        if (!isValidNumberFormatter(num)) return numberIsNotValid;
        return (num as unknown as number).toFixed(1);
      };
      break;
  }
  return retVal;
};
